/* Bourbon */
.top-header .user-pref#currency .currency-cont li a:hover {
  color: #1ca7e0;
}

#menu nav ul li a:hover, #menu nav ul li a.active, .top-header .user-pref#search:hover {
  background-color: #1ca7e0;
  color: #ffffff;
}

/*@include font-face('helvetica_ce_35_thinregular', "../../fonts/webfonts/helvetica-webfont");
@include font-face('montserratregular', "../../fonts/webfonts/montserrat-bold-webfont");
@include font-face('montserratbold', "../../fonts/webfonts/montserrat-regular-webfont");*/
/******************************************************************************/
@font-face {
  font-family: "Gotham-Bold";
  src: url("../../fonts/webfonts/Gotham-Bold.eot");
  src: url("../../fonts/webfonts/Gotham-Bold.eot?#iefix") format("embedded-opentype"), url("../../fonts/webfonts/Gotham-Bold.woff") format("woff"), url("../../fonts/webfonts/Gotham-Bold.ttf") format("truetype"), url("../../fonts/webfonts/Gotham-Bold.svg#Gotham-Bold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham-Book";
  src: url("../../fonts/webfonts/Gotham-Book.eot");
  src: url("../../fonts/webfonts/Gotham-Book.eot?#iefix") format("embedded-opentype"), url("../../fonts/webfonts/Gotham-Book.woff") format("woff"), url("../../fonts/webfonts/Gotham-Book.ttf") format("truetype"), url("../../fonts/webfonts/Gotham-Book.svg#Gotham-Book") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham-Light";
  src: url("../../fonts/webfonts/Gotham-Light.eot");
  src: url("../../fonts/webfonts/Gotham-Light.eot?#iefix") format("embedded-opentype"), url("../../fonts/webfonts/Gotham-Light.woff") format("woff"), url("../../fonts/webfonts/Gotham-Light.ttf") format("truetype"), url("../../fonts/webfonts/Gotham-Light.svg#Gotham-Light") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Exo-Black";
  src: url("../../fonts/webfonts/Exo-Black.eot");
  src: url("../../fonts/webfonts/Exo-Black.eot?#iefix") format("embedded-opentype"), url("../../fonts/webfonts/Exo-Black.woff") format("woff"), url("../../fonts/webfonts/Exo-Black.ttf") format("truetype"), url("../../fonts/webfonts/Exo-Black.svg#Exo-Black") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Exo-Bold";
  src: url("../../fonts/webfonts/Exo-Bold.eot");
  src: url("../../fonts/webfonts/Exo-Bold.eot?#iefix") format("embedded-opentype"), url("../../fonts/webfonts/Exo-Bold.woff") format("woff"), url("../../fonts/webfonts/Exo-Bold.ttf") format("truetype"), url("../../fonts/webfonts/Exo-Bold.svg#Exo-Bold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Exo-Regular";
  src: url("../../fonts/webfonts/Exo-Regular.eot");
  src: url("../../fonts/webfonts/Exo-Regular.eot?#iefix") format("embedded-opentype"), url("../../fonts/webfonts/Exo-Regular.woff") format("woff"), url("../../fonts/webfonts/Exo-Regular.ttf") format("truetype"), url("../../fonts/webfonts/Exo-Regular.svg#Exo-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Exo-Light";
  src: url("../../fonts/webfonts/Exo-Light.eot");
  src: url("../../fonts/webfonts/Exo-Light.eot?#iefix") format("embedded-opentype"), url("../../fonts/webfonts/Exo-Light.woff") format("woff"), url("../../fonts/webfonts/Exo-Light.ttf") format("truetype"), url("../../fonts/webfonts/Exo-Light.svg#Exo-Light") format("svg");
  font-weight: normal;
  font-style: normal;
}

.directory_search .directory_search_bottom .store_listing_list .store_listing_section .store_listing_title {
  font-family: 'Gotham-Bold';
  font-size: 24px;
}

.bottom-footer .page_footer_text, #landing_page_footer .landing_page_bottom .landing_page_footer_text {
  font-family: 'Gotham-Light';
  font-size: 10px;
}

#landing_page .landing_page_navigation > div .landing_page_navigation_item .landing_page_navigation_item_content .landing_page_navigation_item_bottom .landing_page_navigation_item_link div a, .store-details .store-details-related .store-details-related-title {
  font-family: 'Gotham-Book';
  font-size: 20px;
}

#menu nav ul li a, #menu nav ul li ul li a, .banner_extras .banner_extras_bottom .banner_extras_scroll .scroll_text, #breadcrumb .text, #breadcrumb .crumb, .bottom-footer .sitemap, .bottom-footer .sitemap a, .bottom-footer .sitemap span {
  font-family: 'Exo-Regular';
  font-size: 14px;
}

#back_to_top .totop_text, footer #footer-content.inner .bottom-footer #back_to_top2 .totop_text, #audio_status_mobile span, #audio .audio_status span {
  font-family: 'Gotham-Book';
  font-size: 10px;
}

#banner .descs .desc .title {
  font-family: 'Exo-Regular';
  font-size: 60px;
}

#banner .descs .desc p {
  font-family: 'Exo-Light';
  font-size: 25px;
}

.banner_extras .banner_extras_top .banner_extras_top_item .banner_extras_top_title, .store-details .store-details-top .store-details-left .store-details-description .store-details-info-desc {
  font-family: 'Gotham-Book';
  font-size: 16px;
}

.banner_extras .banner_extras_top .banner_extras_top_item .banner_extras_top_subtitle, .store-details .store-details-top .store-details-left .store-details-description .store-details-info-extras .store-details-info-extras-content span {
  font-family: 'Gotham-Bold';
  font-size: 16px;
}

.banner_extras .banner_extras_bottom .banner_extras_search .search_input input, .banner_extras .banner_extras_bottom .banner_extras_search .search_input select, #newsletter .newsletter-input input {
  font-family: 'Gotham-Light';
  font-size: 12px;
}

.banner_extras .banner_extras_bottom .banner_extras_search .search_button button {
  font-family: 'Exo-Bold';
  font-size: 20px;
}

.bottom-footer .sitemap a span, .bottom-footer .sitemap span span {
  font-family: 'Exo-Black';
  font-size: 14px;
}

#promotion_listing .promotion_listing_header .promotion_listing_title {
  font-family: 'Exo-Regular';
  font-size: 40px;
}

#promotion_listing .promotion_listing_header .promotion_listing_subtitle, .innerpage_header .innerpage_title {
  font-family: 'Exo-Black';
  font-size: 40px;
}

#promotion_listing .promotion_listing_list .promotion_list .promotion_item a > div .promotion_item_text .promotion_item_name, #promotion_listing .promotion_listing_list .promotion_list .promotion_item a > div .promotion_item_content .promotion_item_date, #promotion_listing .promotion_listing_list .promotion_list .promotion_item a > div .promotion_item_content .promotion_item_location, .directory_search .directory_search_top #directory_search_list .nav-item .nav-link, .store-details .store-details-floorplan .store-details-floorplan-title, .store-details .store-details-related .store-details-related-listing .store-details-related-list .store-details-related-extras .store-details-related-extras-content span, .promotion_listing_sorting form .form_input, .promotion_listing_sorting form select, .promotion_listing_sorting form .select2 .select2-selection__rendered, .promotion_listing_sorting form #daterange, .news_listing_list .news_list .news_item a > div .news_item_text .news_item_name, .news_listing_list .news_list .news_item a > div .news_item_content .news_item_date, .gallery_listing_list .gallery_list .gallery_item a > div .gallery_item_text .gallery_item_name, .gallery_listing_list .gallery_list .gallery_item a > div .gallery_item_content .gallery_item_date, .gallery_listing_list .gallery_list .gallery_item a > div .gallery_item_content .gallery_item_location, .search-list .search-item-extras .search-item-extras-content span {
  font-family: 'Gotham-Bold';
  font-size: 13px;
}

#promotion_listing .promotion_listing_list .promotion_list .promotion_item a > div .promotion_item_text .promotion_item_description, #home-bottom > .container > .row > div.home_bottom_item .home_bottom_subtitle, #home-bottom > .container > .row > div.home_bottom_item .home_bottom_button > div a, .directory_search .directory_search_bottom .store_listing_list .store_listing_section .store_listing_items .store_listing_item a .store_name, .directory_search .directory_search_bottom .store_listing_list .store_listing_section .store_listing_items .store_listing_item a .store_location, .news_listing_list .news_list .news_item a > div .news_item_text .news_item_description, .gallery_listing_list .gallery_list .gallery_item a > div .gallery_item_text .gallery_item_description {
  font-family: 'Gotham-Book';
  font-size: 13px;
}

#newsletter .newsletter-title {
  font-family: 'Exo-Regular';
  font-size: 22px;
}

footer #footer-content h4, .top-footer .footer-nav-1 h4, .top-footer .footer-nav-2 h4 {
  font-family: 'Exo-Bold';
  font-size: 13px;
}

#promotion_listing .promotion_listing_categories .promotion_listing_category .promotion_listing_appended, #promotion_listing .promotion_listing_list .promotion_list .promotion_item a > div .promotion_item_content .promotion_item_category span, .directory_search .directory_search_bottom .store_listing_categories .store_listing_category .store_listing_appended {
  font-family: 'Exo-Light';
  font-size: 12px;
}

#home-bottom > .container > .row > div.home_bottom_item .home_bottom_title {
  font-family: 'Exo-Bold';
  font-size: 18px;
}

#home-bottom > .container > .row > div.home_bottom_item .home_bottom_title span {
  font-family: 'Exo-Black';
  font-size: 18px;
}

#newsletter .newsletter-title span {
  font-family: 'Exo-Black';
  font-size: 22px;
}

#newsletter .newsletter-button button, .store-details .store-details-related .store-details-related-listing .store-details-related-list .store-details-related-top .store-details-related-top-title .wrap .title, .search-list .search-item-top .search-item-top-title .wrap .title {
  font-family: 'Exo-Bold';
  font-size: 16px;
}

.top-footer .footer-nav-1 li a, .top-footer .footer-nav-2 li a, .top-footer .footer-nav-1 li, .top-footer .footer-nav-2 li, .select2-container .select2-results ul.select2-results__options li.select2-results__option {
  font-family: 'Gotham-Book';
  font-size: 11px;
}

.pagination .pagination_text a, .pagination .pagination_number_wrapper .pagination_number a {
  font-family: 'Gotham-Book';
  font-size: 14px;
}

.store-details .store-details-top .store-details-left .store-details-description .store-details-info-title {
  font-family: 'Gotham-Bold';
  font-size: 12px;
}

.store-details .store-details-related .store-details-related-title span {
  font-family: 'Gotham-Bold';
  font-size: 20px;
}

.store-details .store-details-related .store-details-related-listing .store-details-related-list .store-details-related-top .store-details-related-top-title .wrap .category, .search-list .search-item-top .search-item-top-title .wrap .category {
  font-family: 'Gotham-Light';
  font-size: 8px;
}

/* Bourbon */
.clearfix,
.group {
  zoom: 1;
}

.clearfix:before, .clearfix:after,
.group:before,
.group:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden;
}

.clearfix:after,
.group:after {
  clear: both;
}

.buffer-xs-0 {
  margin-top: 0;
}

.buffer-xs-10 {
  margin-top: 10px;
}

.buffer-xs-20 {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .buffer-sm-0 {
    margin-top: 0;
  }
  .buffer-sm-10 {
    margin-top: 10px;
  }
}

@media (min-width: 992px) {
  .buffer-md-0 {
    margin-top: 0;
  }
  .buffer-md-10 {
    margin-top: 10px;
  }
}

.icheckbox_square-blue,
.iradio_square-blue {
  background-image: url("../../img/iCheck/square/blue.png");
  /* HiDPI support */
}

@media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
  .icheckbox_square-blue,
  .iradio_square-blue {
    background-image: url("../../img/iCheck/square/blue@2x.png");
  }
}

.bannerMfp .mfp-iframe {
  background: none;
  box-shadow: none;
}

.mfp-bg {
  z-index: 999998;
}

.mfp-wrap {
  z-index: 999999;
}

.uno-mfp-popup {
  max-width: 600px;
  margin: 10px auto;
  color: #000;
  font-family: helvetica, arial, sans-serif;
  position: relative;
}

.uno-mfp-popup.white {
  background-color: white;
}

.uno-mfp-popup.white .mfp-close {
  color: black;
  font-size: 22px;
}

.uno-mfp-popup.white > .box {
  border-radius: 0;
}

.uno-mfp-popup > .popup-container {
  padding: 20px;
}

.uno-mfp-popup > .popup-container > h4 {
  margin: 0;
}

.mfp-iframe-holder .mfp-content {
  max-width: 1200px;
}

/* overlay at start */
.mfp-fade.mfp-bg {
  opacity: 0;
  transition: all 0.15s ease-out;
}

/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}

/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.15s ease-out;
}

/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

.mfp-close-btn-in .mfp-close {
  color: #FFF;
}

.select2-dropdown, .select2-container--bootstrap .select2-search--dropdown .select2-search__field, .select2-container--bootstrap .select2-selection {
  border-radius: 0;
}

.slick-loading .slick-list {
  background-image: url("../../img/slick/ajax-loader.gif");
}

@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url("../../fonts/slick/slick.eot");
  src: url("../../fonts/slick/slick.eot?#iefix") format("embedded-opentype"), url("../../fonts/slick/slick.woff") format("woff"), url("../../fonts/slick/slick.ttf") format("truetype"), url("../../fonts/slick/slick.svg#slick") format("svg");
}

.slick-arrow:before {
  transition: opacity 0.5s;
}

/* TinyMCE Fullscreen Z-Index Override */
div.mce-fullscreen {
  z-index: 9999;
}

label.mce-label {
  max-width: none;
}

/* TINYMCE CUSTOMISATION */
.mce-window {
  max-width: 90% !important;
}

.mce-panel {
  max-width: 100% !important;
}

.mce-tabs {
  max-width: 100% !important;
}

.mce-container-body {
  max-width: 100% !important;
}

.mce-container {
  max-width: 100% !important;
}

.mejs-overlay-button {
  background-image: url("../../assets/mediaelement/bigplay.svg");
}

.no-svg .mejs-overlay-button {
  background-image: url("../../assets/mediaelement/bigplay.png");
}

.mejs-overlay-loading {
  background-image: url("../../assets/mediaelement/background.png");
}

.mejs-overlay-loading span {
  background-image: url("../../assets/mediaelement/loading.gif");
}

.mejs-container .mejs-controls,
.mejs-controls .mejs-volume-button .mejs-volume-slider,
.mejs-controls .mejs-captions-button .mejs-captions-selector,
.mejs-captions-text,
.mejs-controls .mejs-sourcechooser-button .mejs-sourcechooser-selector,
.mejs-postroll-layer,
.mejs-postroll-close,
.mejs-controls .mejs-speed-button .mejs-speed-selector {
  background-image: url("../../assets/mediaelement/background.png");
}

.mejs-controls .mejs-button button {
  background-image: url("../../assets/mediaelement/controls.svg");
}

.no-svg .mejs-controls .mejs-button button {
  background-image: url("../../assets/mediaelement/controls.png");
}

.mejs-controls .mejs-button.mejs-skip-back-button {
  background-image: url("../../assets/mediaelement/skipback.png");
}

.jstree-default .jstree-icon,
.jstree-default .jstree-file,
.jstree-default .jstree-folder {
  background-image: url("../../img/jstree/32px.png");
}

.jstree-default > .jstree-container-ul .jstree-loading > .jstree-ocl {
  background-image: url("../../img/jstree/throbber.gif");
}

#jstree-dnd.jstree-default .jstree-ok,
#jstree-dnd.jstree-default .jstree-er,
#jstree-dnd.jstree-default-small .jstree-ok,
#jstree-dnd.jstree-default-small .jstree-er,
.jstree-default-large .jstree-node,
.jstree-default-large .jstree-icon,
#jstree-dnd.jstree-default-large .jstree-ok,
#jstree-dnd.jstree-default-large .jstree-er {
  background-image: url("../../img/jstree/32px.png");
}

.jstree-default-small .jstree-icon,
.jstree-default-small .jstree-file,
.jstree-default-small .jstree-folder {
  background-image: url("../../img/jstree/32px.png");
}

.jstree-default-large .jstree-icon,
.jstree-default-large .jstree-file,
.jstree-default-large .jstree-folder {
  background-image: url("../../img/jstree/32px.png");
}

#jstree-dnd.jstree-dnd-responsive > .jstree-ok,
#jstree-dnd.jstree-dnd-responsive > .jstree-er {
  background-image: url("../../img/jstree/40px.png");
}

.jstree-default-responsive .jstree-node,
.jstree-default-responsive .jstree-icon,
.jstree-default-responsive .jstree-node > .jstree-ocl,
.jstree-default-responsive .jstree-themeicon,
.jstree-default-responsive .jstree-checkbox,
.jstree-default-responsive .jstree-file,
.jstree-default-responsive .jstree-folder {
  background-image: url("../../img/jstree/40px.png");
}

/* No bold */
.no-bold {
  font-weight: normal;
}

/* IE Image Fix */
img {
  -ms-interpolation-mode: bicubic;
}

/* Easy background image */
.bg-img {
  background: no-repeat center;
}

.bg-img.bg-cover {
  background-size: cover;
}

.bg-img.bg-contain {
  background-size: contain;
}

/* Button Block Text Overflow */
.btn-block {
  text-overflow: ellipsis;
  overflow: hidden;
}

/* Hide CSRF Token */
#_token {
  display: none;
}

/* Bootstrap: Remove gutter */
.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

/* Remove Transition */
.no-transition {
  transition: none !important;
}

a.block {
  display: block;
}

.btn:focus {
  outline: none;
}

body {
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
}

#newsletter .form-group .form-control {
  font-size: 14px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

#news-mini .news .slide .date {
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}

body {
  line-height: 1.2;
}

blockquote {
  border-left: 2px solid #1271db;
  font-family: Georgia, Times, "Times New Roman", serif;
  font-size: 1.4em;
  font-style: italic;
  margin: 0 0 20px;
  padding-left: 25px;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Open Sans", sans-serif;
  line-height: 1;
  font-weight: bold;
}

h1 {
  font-size: 42px;
  font-style: normal;
  margin: 0 0 25px;
}

h2 {
  font-size: 30px;
  font-style: normal;
  margin: 0 0 25px;
}

h3 {
  font-size: 24px;
  font-style: normal;
  margin: 0 0 25px;
}

h4 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  margin: 0 0 25px;
}

h5 {
  font-size: 16px;
  font-style: normal;
  margin: 0 0 25px;
}

p {
  margin: 0 0 20px;
  font-size: 12px;
}

pre {
  background: #222;
  color: #fff;
  display: block;
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
  font-size: 13px;
  line-height: 20px;
  margin: 0 0 20px;
  padding: 10px;
  word-break: break-all;
  word-wrap: break-word;
}

code {
  border-radius: 5px;
  background: #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
  font-size: 13px;
  line-height: 20px;
  padding: 2px 10px;
  word-break: break-all;
  word-wrap: break-word;
}

a {
  color: #1271db;
  display: inline-block;
}

a:hover, a:focus {
  text-decoration: none;
}

/*

// Responsive styles if needed
// @include responsive($big-break) {
// }
@include responsive($small-break) {
  body {
    font-size: 14px;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 10px;
  }

  h1 {
    font-size: 26px;
  }

  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 16px;
  }

  h4,
  h5,
  p,
  ul,
  ol {
    font-size: 14px;
  }
}
*/
html, body, #wrapper {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

#wrapper {
  display: table;
  width: 100%;
  table-layout: fixed;
}

main {
  display: table-row;
}

header {
  display: table-row;
  height: 1px;
}

footer {
  display: table-row;
  height: 1px;
}

.mm-page {
  height: 100%;
}

/* columns of same height styles */
.row-full-height {
  height: 100%;
}

.col-full-height {
  height: 100%;
  vertical-align: middle;
}

.row-same-height {
  display: table;
  table-layout: fixed;
}

/*revert back to normal in md*/
.row-md-same-height {
  display: table;
  table-layout: fixed;
}

@media (max-width: 992px) {
  .row-md-same-height {
    display: block;
  }
}

.row-sm-same-height {
  display: table;
  table-layout: fixed;
}

@media (max-width: 768px) {
  .row-sm-same-height {
    display: block;
  }
}

.col-xs-height {
  display: table-cell;
  float: none !important;
}

/*alignments*/
.col-top {
  vertical-align: top;
}

.col-middle {
  vertical-align: middle;
}

.col-bottom {
  vertical-align: bottom;
}

@media (min-width: 768px) {
  .col-sm-height {
    display: table-cell;
    float: none !important;
  }
}

@media (min-width: 992px) {
  .col-md-height {
    display: table-cell;
    float: none !important;
  }
}

@media (min-width: 1000px) {
  .col-lg-height {
    display: table-cell;
    float: none !important;
  }
}

img,
video,
audio,
iframe,
object {
  max-width: 100%;
}

.btn {
  border-radius: 5px;
  background: transparent;
  border: 1px solid #AD131E;
  color: #AD131E;
  display: inline-block;
  height: 44px;
  outline: none;
  padding: 0 25px;
  text-align: center;
  text-decoration: none;
  text-transform: capitalize;
}

.btn:hover, .btn:focus {
  background: #AD131E;
  border-color: #AD131E;
  color: #fff;
  text-decoration: none;
}

.btn:active {
  background: #7f0e16;
  border-color: #7f0e16;
}

.btn-primary {
  background-color: #AD131E;
  border-color: #AD131E;
  color: #fff;
}

.btn-primary:hover, .btn-primary:focus {
  background: #96101a;
  border-color: #96101a;
  color: #fff;
}

.btn-primary:active {
  background: #7f0e16;
  border-color: #7f0e16;
  color: #fff;
}

.btn-lg {
  font-size: 1.4em;
  height: 66px;
  padding: 0 60px;
}

.btn-sm {
  font-size: .85em;
  height: 30.8px;
  padding: 0 10px;
}

.btn-link {
  box-shadow: none;
  background: transparent;
  border: 0;
  color: #AD131E;
  text-shadow: none;
  text-transform: none;
}

.btn-link:hover, .btn-link:focus {
  background: transparent;
  color: #AD131E;
  text-decoration: underline;
}

@media screen and (max-width: 480px) {
  .btn {
    display: block;
    margin-bottom: 10px;
    width: 100%;
  }
}

table {
  width: 100%;
}

table th,
table td {
  padding: 5px;
  text-align: left;
}

table th {
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type=text],
input[type=email],
input[type=phone],
input[type=password],
input[type=number],
input[type=search],
textarea {
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border: 1px solid #ccc;
  height: 44px;
  outline: none;
  padding: 5px 10px;
  width: 300px;
}

input[type=text]:focus,
input[type=email]:focus,
input[type=phone]:focus,
input[type=password]:focus,
input[type=number]:focus,
input[type=search]:focus,
textarea:focus {
  border-color: #AD131E;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(173, 19, 30, 0.6);
}

input[type=text].full-width,
input[type=email].full-width,
input[type=phone].full-width,
input[type=password].full-width,
input[type=number].full-width,
input[type=search].full-width,
textarea.full-width {
  width: 100%;
}

textarea {
  height: 132px;
}

form {
  margin-bottom: 40px;
}

form .field {
  margin-bottom: 20px;
}

form .hint {
  color: #aaa;
  margin-top: 5px;
}

form .is-required {
  font-style: italic;
}

form .has-error .error-description {
  color: #ff4136;
  margin-top: 5px;
}

form .has-error input[type=text],
form .has-error input[type=email],
form .has-error input[type=phone],
form .has-error input[type=password],
form .has-error input[type=number],
form .has-error input[type=search],
form .has-error textarea {
  border-color: #ff4136;
}

.alert {
  zoom: 1;
  border-left: 3px solid;
  margin-bottom: 40px;
  padding: 10px;
}

.alert:before, .alert:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden;
}

.alert:after {
  clear: both;
}

.alert.alert-danger {
  background: #ffeae9;
  border-color: #ff4136;
  color: #e90d00;
}

.alert.alert-success {
  background: #e6f9e8;
  border-color: #2ecc40;
  color: #25a233;
}

.alert.alert-warning {
  background: #fff2e7;
  border-color: #ff851b;
  color: #e76b00;
}

.alert.alert-info {
  background: #cfe9ff;
  border-color: #0074d9;
  color: #0059a6;
}

.alert.alert-empty {
  background: #f4f4f4;
  border: 1px dashed #ddd;
  text-align: center;
}

.alert p:last-child {
  margin-bottom: 0;
}

#menu nav ul li a:hover, #menu nav ul li a.active, .top-header .user-pref#search:hover {
  background-color: #EA0005;
  color: #FFFFFF !important;
}

#menu {
  padding: 0;
}

#menu nav {
  float: right;
}

#menu nav ul {
  list-style: none;
  position: relative;
  float: left;
  padding: 0;
  margin: 20px 0 0 0;
}

#menu nav ul li {
  position: relative;
  float: left;
  margin: 0;
  padding: 0;
}

#menu nav ul li.active {
  color: #EA0005;
}

#menu nav ul li.active a {
  color: #EA0005 !important;
}

#menu nav ul li a {
  color: #FFFFFF;
  display: block;
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 0;
  text-transform: uppercase;
  transition: all 0.5s ease-in-out;
}

#menu nav ul li:hover > a {
  color: #FFFFFF !important;
  background: #EA0005;
}

#menu nav ul li:hover > ul {
  max-height: 1000px;
  transition: max-height 1s cubic-bezier(1, 0, 1, 1);
}

#menu nav ul li:hover.active > a {
  color: #FFFFFF !important;
  background: #EA0005;
}

#menu nav ul li:hover.active li > a {
  color: #FFFFFF !important;
}

#menu nav ul li ul {
  color: white;
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  padding: 0;
  z-index: 99999;
  margin: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s cubic-bezier(0, 1, 0, 1);
}

#menu nav ul li ul li {
  float: none;
  width: 200px;
}

#menu nav ul li ul li a {
  border-radius: 0;
  background-color: #CE0000;
  color: #FFFFFF !important;
  padding: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

#menu nav ul li ul li:hover > a {
  background-color: #EA0005;
  color: #FFFFFF !important;
}

#menu nav ul li ul li ul {
  top: 0;
  right: 100%;
  padding: 0;
  text-indent: 3px;
  font-size: 13px;
}

* {
  box-sizing: border-box;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.clear {
  clear: both;
}

.right {
  float: right;
}

.left {
  float: left;
}

.list-unstyled {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

.valign {
  vertical-align: middle;
}

hr {
  border: 0;
  border-bottom: 1px solid #ddd;
  margin: 40px 0;
}

.subtle {
  color: #aaa;
}

.relative {
  position: relative;
}

.slick-slide, .slick-slide * {
  outline: none !important;
}

.swal2-popup .swal2-styled:focus {
  outline: none !important;
  box-shadow: none !important;
}

.swal2-icon.swal2-success {
  box-sizing: initial !important;
}

.swal2-content {
  font-size: 15px !important;
}

.imgbg {
  opacity: 0;
}

.mfp-wrap {
  position: fixed !important;
  top: 0 !important;
}

.uno-mfp-popup {
  max-width: 100%;
}

@media (min-width: 0px) {
  .uno-mfp-popup {
    width: 90%;
  }
}

@media (min-width: 768px) {
  .uno-mfp-popup {
    width: 35%;
  }
}

.uno-mfp-popup > .popup-container {
  padding: 40px 20px 20px;
}

.search_noresult {
  font-family: 'Gotham-Book';
  font-size: 15px;
}

/*******************************************************************************/
.no-padding {
  padding: 0;
}

.left-0-padding {
  padding-left: 0;
}

.right-0-padding {
  padding-right: 0;
}

.top-0-padding {
  padding-top: 0;
}

.bottom-0-padding {
  padding-bottom: 0;
}

.dark-blue {
  color: #004161;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.title-lg-inline {
  display: inline-block;
}

@media (max-width: 991px) {
  .title-lg-inline {
    display: block;
  }
}

.title-md-inline {
  display: inline-block;
}

@media (max-width: 768px) {
  .title-md-inline {
    display: block;
  }
}

.center-of-unknown:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.center-of-unknown > * {
  display: inline-block;
  vertical-align: middle;
  max-width: 98%;
}

#header-content {
  position: relative;
}

.top-header {
  background-color: #2C2C2C;
  padding: 0;
  color: #ffffff;
  display: none;
  /*end of USER-PREF*/
}

.top-header .user-pref {
  float: right;
  /*end of currency*/
  /*end of search*/
}

.top-header .user-pref#currency .currency-cont {
  margin: 0;
  padding: 0;
  /*end of li*/
}

.top-header .user-pref#currency .currency-cont li {
  display: inline-block;
  border-left: 1px solid #151515;
  box-shadow: rgba(255, 255, 255, 0.2) -1px 0 0 0;
  padding: 10px;
  /*end of a*/
}

.top-header .user-pref#currency .currency-cont li * {
  display: inline-block;
  vertical-align: middle;
}

.top-header .user-pref#currency .currency-cont li a {
  text-transform: uppercase;
  color: white;
  font-size: 10px;
  text-decoration: none;
  line-height: 1;
  transition: all 0.5s ease-in-out;
}

.top-header .user-pref#currency .currency-cont li .flag {
  vertical-align: middle;
}

.top-header .user-pref#search {
  padding: 0 15px;
  line-height: 36px;
  cursor: pointer;
  border-left: 1px solid #151515;
  border-right: 1px solid #151515;
  box-shadow: rgba(255, 255, 255, 0.2) -1px 0 0 0, rgba(255, 255, 255, 0.2) 1px 0 0 0;
  transition: all 0.5s ease-in-out;
}

.top-header .user-pref#search.active {
  background-color: #369FDC;
}

.bottom-header {
  background: #370C10;
  padding: 20px 0;
}

.bottom-header.inner {
  background: #FFFFFF;
}

.bottom-header.inner #menu nav ul li {
  color: #2F2F32;
}

.bottom-header.inner #menu nav ul li.active {
  color: #EA0005;
}

.bottom-header.inner #menu nav ul li a {
  color: #2F2F32;
}

.bottom-header.inner #menu nav ul li a.active {
  color: #EA0005;
}

.bottom-header.inner #audio, .bottom-header.inner #audio_status_mobile {
  display: none !important;
}

.bottom-header.inner #menuBtn a {
  color: #95101A !important;
}

.bottom-header.inner #menuBtn a i {
  color: #95101A !important;
}

.bottom-header #logo {
  margin: 30px 0;
  padding: 0;
}

@media (max-width: 991px) {
  .bottom-header #logo {
    margin: 5px 0;
    text-align: center;
  }
}

.bottom-header #logo a {
  display: block;
  text-align: center;
}

#menuBtn {
  position: absolute;
  top: 20%;
  left: 30px;
  font-size: 38px;
}

#menuBtn a {
  color: #FFFFFF;
}

#menuBtn a:focus {
  outline: none !important;
}

#menuBtn a i {
  font-size: 24px;
  color: #FFFFFF;
}

#banner {
  position: relative;
  transition: all 0.5s ease-in-out;
  /*end of slides*/
  /*end of descs*/
}

#banner .slides {
  margin-bottom: 0;
}

#banner .slides .slide {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 700px;
}

@media (max-width: 768px) {
  #banner .slides .slide {
    height: 450px;
  }
}

@media (max-width: 480px) {
  #banner .slides .slide {
    height: 350px;
  }
}

#banner .slides .slide[href="#"] {
  cursor: default;
}

#banner .descs {
  width: 100%;
  max-width: 550px;
  position: absolute;
  padding: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: opacity 0.5s ease-in-out;
}

@media (max-width: 768px) {
  #banner .descs {
    max-width: none;
  }
}

#banner .descs .desc .title {
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 15px;
  letter-spacing: 8px;
}

#banner .descs .desc p {
  color: #FFFFFF;
  letter-spacing: 1px;
}

#banner .descs .desc .link {
  color: #004161;
  font-size: 13px;
  position: relative;
}

#banner .descs .desc .link:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-color: #004161;
  top: 50%;
  left: 100%;
  margin-left: 5px;
  margin-top: -3px;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 3px solid #004161;
  border-left-color: inherit;
}

#banner .descs.slick-slider .slick-dots {
  bottom: 0;
  left: 0;
}

#banner .descs.slick-slider .slick-dots li button {
  width: 10px;
  height: 10px;
}

#banner .descs.slick-slider .slick-dots li button:before {
  font-size: 10px;
  color: #1ca7e0;
  transition: all 0.5s ease;
  opacity: 0.5;
  width: auto;
  height: auto;
}

#banner .descs.slick-slider .slick-dots li.slick-active button:before, #banner .descs.slick-slider .slick-dots li button:hover:before {
  color: #004161;
  opacity: 1;
  font-size: 12px;
}

#banner .descs.slick-slider .slick-prev:before, #banner .descs.slick-slider .slick-next:before {
  color: #004161;
}

#banner .descs.slick-slider .slick-slide:focus {
  outline: none;
}

#banner .banner_arrows {
  position: absolute;
  width: 100%;
  top: 45%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 20px;
}

@media (max-width: 1025px) {
  #banner .banner_arrows {
    display: none;
  }
}

#banner .banner_arrows .slick-prev {
  width: 50px;
  height: 50px;
  background: transparent;
  text-align: center;
  z-index: 1;
  transition: all linear 0.3s;
}

#banner .banner_arrows .slick-prev:before {
  content: '\f053';
  font-family: 'fontAwesome';
  font-size: 22px;
  color: #FFFFFF;
  line-height: 50px;
  padding-right: 3px;
  opacity: 1 !important;
  transition: all linear 0.3s;
}

#banner .banner_arrows .slick-prev:hover:before {
  color: #EA0005;
}

@media (max-width: 1280px) {
  #banner .banner_arrows .slick-prev {
    left: 0;
  }
}

#banner .banner_arrows .slick-next {
  width: 50px;
  height: 50px;
  background: transparent;
  text-align: center;
  z-index: 1;
  transition: all linear 0.3s;
}

#banner .banner_arrows .slick-next:before {
  content: '\f054';
  font-family: 'fontAwesome';
  font-size: 22px;
  color: #FFFFFF;
  line-height: 50px;
  padding-left: 3px;
  opacity: 1 !important;
  transition: all linear 0.3s;
}

#banner .banner_arrows .slick-next:hover:before {
  color: #EA0005;
}

@media (max-width: 1280px) {
  #banner .banner_arrows .slick-next {
    right: 0;
  }
}

@-webkit-keyframes pulse_animation {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

@keyframes pulse_animation {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

.banner_extras {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.banner_extras .banner_extras_top {
  margin-bottom: 40px;
}

.banner_extras .banner_extras_top .banner_extras_top_item {
  float: right;
}

.banner_extras .banner_extras_top .banner_extras_top_item:first-child .banner_extras_top_image img {
  -webkit-animation: pulse_animation 3000ms infinite linear;
  animation: pulse_animation 3000ms infinite linear;
}

.banner_extras .banner_extras_top .banner_extras_top_item.banner_extras_top_item_location .banner_extras_top_image_b {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 0;
  transition: all 0.3s linear;
}

.banner_extras .banner_extras_top .banner_extras_top_item.banner_extras_top_item_location:hover .banner_extras_top_image_a {
  opacity: 0;
  transition: all 0.3s linear;
}

.banner_extras .banner_extras_top .banner_extras_top_item.banner_extras_top_item_location:hover .banner_extras_top_image_b {
  opacity: 1;
}

.banner_extras .banner_extras_top .banner_extras_top_item:first-child {
  padding-right: 0;
  position: relative;
}

.banner_extras .banner_extras_top .banner_extras_top_item:first-child:after {
  content: '';
  border-style: solid;
  border-width: 0 10px 15px 10px;
  border-color: transparent transparent rgba(234, 0, 5, 0.8) transparent;
  position: absolute;
  bottom: -40px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

@media (max-width: 991px) {
  .banner_extras .banner_extras_top .banner_extras_top_item:first-child:after {
    border-color: transparent transparent #ea0005 transparent;
  }
}

.banner_extras .banner_extras_top .banner_extras_top_item .banner_extras_top_image {
  text-align: center;
  margin-bottom: 20px;
}

.banner_extras .banner_extras_top .banner_extras_top_item .banner_extras_top_title {
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  letter-spacing: 1px;
  margin-bottom: 5px;
}

@media (max-width: 480px) {
  .banner_extras .banner_extras_top .banner_extras_top_item .banner_extras_top_title {
    font-size: 12px;
  }
}

.banner_extras .banner_extras_top .banner_extras_top_item .banner_extras_top_title.search {
  color: #FFFFFF;
}

.banner_extras .banner_extras_top .banner_extras_top_item .banner_extras_top_subtitle {
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  letter-spacing: 1px;
}

@media (max-width: 480px) {
  .banner_extras .banner_extras_top .banner_extras_top_item .banner_extras_top_subtitle {
    font-size: 12px;
  }
}

.banner_extras .banner_extras_top .banner_extras_top_item .banner_extras_top_subtitle a {
  color: #FFFFFF;
  text-decoration: none;
  transition: all 0.3s linear;
}

.banner_extras .banner_extras_top .banner_extras_top_item .banner_extras_top_subtitle a:hover {
  color: #EA0005;
}

.banner_extras .banner_extras_top .banner_extras_top_item .banner_extras_top_subtitle.search {
  color: #FFFFFF;
}

.banner_extras .banner_extras_bottom {
  background: rgba(234, 0, 5, 0.8);
  height: 120px;
  padding-top: 35px;
}

@media (max-width: 991px) {
  .banner_extras .banner_extras_bottom {
    background: #ea0005;
    height: auto;
    padding-top: 10px;
  }
}

.banner_extras .banner_extras_bottom .banner_extras_search form {
  margin: 0;
}

.banner_extras .banner_extras_bottom .banner_extras_search .search_input {
  position: relative;
}

.banner_extras .banner_extras_bottom .banner_extras_search .search_input input {
  box-shadow: none;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #FFFFFF;
  width: 100%;
  background: transparent;
  color: #FFFFFF;
  height: 44px;
  padding: 12px 10px 12px;
  text-transform: uppercase;
}

.banner_extras .banner_extras_bottom .banner_extras_search .search_input input::-webkit-input-placeholder {
  color: #FFFFFF;
  font-family: 'Gotham-Light';
  font-size: 12px;
}

.banner_extras .banner_extras_bottom .banner_extras_search .search_input input::-moz-placeholder {
  color: #FFFFFF;
  font-family: 'Gotham-Light';
  font-size: 12px;
}

.banner_extras .banner_extras_bottom .banner_extras_search .search_input input:-moz-placeholder {
  color: #FFFFFF;
  font-family: 'Gotham-Light';
  font-size: 12px;
}

.banner_extras .banner_extras_bottom .banner_extras_search .search_input input:-ms-input-placeholder {
  color: #FFFFFF;
  font-family: 'Gotham-Light';
  font-size: 12px;
}

.banner_extras .banner_extras_bottom .banner_extras_search .search_input select {
  color: #FFFFFF;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  border-bottom: 1px solid #FFFFFF;
  text-transform: uppercase;
  height: 44px;
  width: 100%;
  outline: none;
  padding: 12px 10px 12px;
}

.banner_extras .banner_extras_bottom .banner_extras_search .search_input select option {
  color: #2F2F32;
  font-weight: bold;
}

.banner_extras .banner_extras_bottom .banner_extras_search .search_input select::-ms-expand {
  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  /* IE 5-7 */
  filter: alpha(opacity=0);
  /* Good browsers :) */
  opacity: 0;
  display: none;
}

.banner_extras .banner_extras_bottom .banner_extras_search .search_input i {
  color: #FFFFFF;
  font-size: 15px;
  position: absolute;
  top: 18px;
  right: 30px;
}

.banner_extras .banner_extras_bottom .banner_extras_search .search_button button {
  color: #FFFFFF;
  background: #AD131E;
  border: 1px solid #AD131E;
  text-transform: uppercase;
  letter-spacing: 4px;
  padding: 15px 30px;
  border-radius: 25px;
  width: 90%;
  transition: all 0.3s linear;
}

.banner_extras .banner_extras_bottom .banner_extras_search .search_button button:hover {
  background: #95101A;
}

@media (max-width: 1280px) {
  .banner_extras .banner_extras_bottom .banner_extras_search .search_button button {
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  .banner_extras .banner_extras_bottom .banner_extras_search .search_button {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
}

.banner_extras .banner_extras_bottom .banner_extras_scroll {
  padding-top: 5px;
}

.banner_extras .banner_extras_bottom .banner_extras_scroll .scroll_text {
  color: #FFFFFF;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 30px);
}

.banner_extras .banner_extras_bottom .banner_extras_scroll .scroll_img {
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 991px) {
  .banner_extras .banner_extras_bottom .banner_extras_scroll {
    display: none;
  }
}

.banner_socialmedia {
  position: fixed;
  top: 50%;
  right: 25px;
  z-index: 3;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media (max-width: 991px) {
  .banner_socialmedia {
    right: auto;
    left: 50px;
  }
}

@media (max-width: 768px) {
  .banner_socialmedia {
    left: 30px;
    top: 30%;
  }
}

@media (max-width: 480px) {
  .banner_socialmedia {
    left: inherit;
    right: 12px;
    text-align: right;
    top: 50%;
  }
}

.banner_socialmedia ul {
  display: inline-block;
  padding: 0;
  list-style: none;
}

.banner_socialmedia ul li {
  font-size: 16px;
  margin-bottom: 10px;
}

.banner_socialmedia ul li a {
  color: #FF0000;
  padding: 0 5px;
  border-radius: 100px;
  border: 1px solid #FFFFFF;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  background: #FFFFFF;
  transition: all 0.3s linear;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
}

@media (max-width: 480px) {
  .banner_socialmedia ul li a {
    width: 38px;
    height: 38px;
    line-height: 37px;
  }
}

.banner_socialmedia ul li a i {
  font-size: 15px;
}

.banner_socialmedia ul li a:hover {
  background: #FF0000;
  border: 1px solid #FF0000;
  color: #FFFFFF;
}

#promotion_listing.home_promotion {
  background: linear-gradient(to bottom, #95101A 0%, #95101A 80%, #FFFFFF 80%, #FFFFFF 100%);
}

#promotion_listing .promotion_listing_header {
  padding-top: 60px;
  padding-bottom: 20px;
}

#promotion_listing .promotion_listing_header .promotion_listing_title {
  color: #FFFFFF;
  text-transform: uppercase;
  letter-spacing: 5px;
}

@media (max-width: 991px) {
  #promotion_listing .promotion_listing_header .promotion_listing_title {
    text-align: center;
  }
}

#promotion_listing .promotion_listing_header .promotion_listing_subtitle {
  color: #FFFFFF;
  text-transform: uppercase;
  letter-spacing: 5px;
}

@media (max-width: 991px) {
  #promotion_listing .promotion_listing_header .promotion_listing_subtitle {
    text-align: center;
  }
}

@media (max-width: 325px) {
  #promotion_listing .promotion_listing_header .promotion_listing_subtitle {
    font-size: 35px;
  }
}

@media (max-width: 1025px) {
  #promotion_listing .promotion_listing_category_wrapper {
    width: 100% !important;
  }
}

#promotion_listing .promotion_listing_categories {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: end;
  justify-content: flex-end;
  flex-wrap: wrap;
}

@media (max-width: 1025px) {
  #promotion_listing .promotion_listing_categories {
    -webkit-box-pack: start;
    justify-content: flex-start;
  }
}

@media (max-width: 991px) {
  #promotion_listing .promotion_listing_categories {
    -webkit-box-pack: center;
    justify-content: center;
  }
}

#promotion_listing .promotion_listing_categories .promotion_listing_category {
  width: 90px;
  min-width: 90px;
  height: 90px;
  background: transparent;
  border-radius: 100px;
  text-align: center;
  margin: 10px;
  line-height: 85px;
  cursor: pointer;
  transition: all 0.3s linear;
  position: relative;
}

#promotion_listing .promotion_listing_categories .promotion_listing_category .promotion_listing_appended {
  position: absolute;
  left: 40%;
  bottom: -15px;
  white-space: nowrap;
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  display: none;
  z-index: 1;
  background: #FF0000;
  color: #FFFFFF;
  font-size: 11px;
  border-radius: 25px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

#promotion_listing .promotion_listing_categories .promotion_listing_category.active {
  background: #AD131E;
}

#promotion_listing .promotion_listing_categories .promotion_listing_category:hover {
  background: #CE0000;
}

@media (max-width: 1280px) and (min-width: 991px) {
  #promotion_listing .promotion_listing_categories .promotion_listing_category {
    width: 80px;
    height: 80px;
    line-height: 75px;
  }
}

#promotion_listing .promotion_listing_list {
  min-height: 412px;
}

#promotion_listing .promotion_listing_list .promotion_list {
  margin: 0 -25px;
  opacity: 0;
}

@media (max-width: 991px) {
  #promotion_listing .promotion_listing_list .promotion_list {
    margin: 0;
  }
}

#promotion_listing .promotion_listing_list .promotion_list.current {
  display: block;
}

#promotion_listing .promotion_listing_list .promotion_list .slick-track {
  padding-bottom: 10px;
}

#promotion_listing .promotion_listing_list .promotion_list .promotion_item {
  margin: 0 25px;
}

@media (max-width: 480px) {
  #promotion_listing .promotion_listing_list .promotion_list .promotion_item {
    margin-bottom: 15px !important;
  }
}

#promotion_listing .promotion_listing_list .promotion_list .promotion_item a {
  background: #F4F4F4;
  display: block;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.25);
  transition: all 0.3s linear;
}

#promotion_listing .promotion_listing_list .promotion_list .promotion_item a:hover {
  background: #FFFFFF;
}

#promotion_listing .promotion_listing_list .promotion_list .promotion_item a:hover .promotion_item_hover_image {
  opacity: 1;
}

#promotion_listing .promotion_listing_list .promotion_list .promotion_item a > div .promotion_item_image img {
  width: 100%;
}

#promotion_listing .promotion_listing_list .promotion_list .promotion_item a > div .promotion_item_text {
  padding: 25px;
}

#promotion_listing .promotion_listing_list .promotion_list .promotion_item a > div .promotion_item_text .promotion_item_name {
  font-size: 16px;
  color: #2F2F32;
  text-transform: uppercase;
  margin-bottom: 5px;
  max-height: 40px;
}

#promotion_listing .promotion_listing_list .promotion_list .promotion_item a > div .promotion_item_text .promotion_item_description {
  color: #757575;
}

#promotion_listing .promotion_listing_list .promotion_list .promotion_item a > div .promotion_item_content {
  padding: 0 25px 25px;
}

#promotion_listing .promotion_listing_list .promotion_list .promotion_item a > div .promotion_item_content .promotion_item_category {
  margin-bottom: 20px;
}

#promotion_listing .promotion_listing_list .promotion_list .promotion_item a > div .promotion_item_content .promotion_item_category span {
  background: #FF0000;
  color: #FFFFFF;
  padding: 5px 20px;
  border-radius: 25px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

@media (max-width: 325px) {
  #promotion_listing .promotion_listing_list .promotion_list .promotion_item a > div .promotion_item_content .promotion_item_category span {
    font-size: 9px;
  }
}

#promotion_listing .promotion_listing_list .promotion_list .promotion_item a > div .promotion_item_content .promotion_item_date {
  color: #2F2F32;
  text-transform: uppercase;
  margin-bottom: 8px;
}

#promotion_listing .promotion_listing_list .promotion_list .promotion_item a > div .promotion_item_content .promotion_item_date i {
  color: #CE0000;
  width: 15px;
}

#promotion_listing .promotion_listing_list .promotion_list .promotion_item a > div .promotion_item_content .promotion_item_location {
  color: #2F2F32;
  text-transform: uppercase;
  margin-bottom: 8px;
}

#promotion_listing .promotion_listing_list .promotion_list .promotion_item a > div .promotion_item_content .promotion_item_location i {
  color: #CE0000;
  width: 15px;
}

#promotion_listing .promotion_listing_list .promotion_list .promotion_item a > div .promotion_item_hover_image {
  position: absolute;
  bottom: 0;
  right: 40px;
  transition: all 0.3s linear;
  opacity: 0;
}

#promotion_listing .promotion_listing_list .promotion_list .slick-prev {
  width: 30px;
  height: 30px;
  background: transparent;
  border-radius: 50px;
  border: 1px solid #FF0000;
  text-align: center;
  z-index: 1;
  transition: all linear 0.3s;
}

#promotion_listing .promotion_listing_list .promotion_list .slick-prev:before {
  content: '\f053';
  font-family: 'fontAwesome';
  font-size: 9px;
  color: #FFFFFF;
  line-height: 28px;
  padding-right: 3px;
  opacity: 1 !important;
  transition: all linear 0.3s;
}

#promotion_listing .promotion_listing_list .promotion_list .slick-prev:hover {
  background: #FF0000;
}

@media (max-width: 1280px) {
  #promotion_listing .promotion_listing_list .promotion_list .slick-prev {
    left: -15px;
  }
}

@media (max-width: 480px) {
  #promotion_listing .promotion_listing_list .promotion_list .slick-prev {
    left: -10px;
  }
}

#promotion_listing .promotion_listing_list .promotion_list .slick-next {
  width: 30px;
  height: 30px;
  background: transparent;
  border-radius: 50px;
  border: 1px solid #FF0000;
  text-align: center;
  z-index: 1;
  transition: all linear 0.3s;
}

#promotion_listing .promotion_listing_list .promotion_list .slick-next:before {
  content: '\f054';
  font-family: 'fontAwesome';
  font-size: 9px;
  color: #FFFFFF;
  line-height: 28px;
  padding-left: 3px;
  opacity: 1 !important;
  transition: all linear 0.3s;
}

#promotion_listing .promotion_listing_list .promotion_list .slick-next:hover {
  background: #FF0000;
}

@media (max-width: 1280px) {
  #promotion_listing .promotion_listing_list .promotion_list .slick-next {
    right: -15px;
  }
}

@media (max-width: 480px) {
  #promotion_listing .promotion_listing_list .promotion_list .slick-next {
    right: -10px;
  }
}

#home-bottom {
  padding: 100px 0;
  position: relative;
}

@media (max-width: 991px) {
  #home-bottom {
    padding: 50px 0;
  }
}

#home-bottom > .bgimg {
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: cover !important;
  background-position: center center !important;
  top: 0;
}

@media (max-width: 991px) {
  #home-bottom > .bgimg {
    opacity: 0;
  }
}

#home-bottom > .container > .row {
  margin-left: -25px;
  margin-right: -25px;
}

@media (max-width: 991px) {
  #home-bottom > .container > .row {
    margin-left: -15px;
    margin-right: -15px;
  }
}

#home-bottom > .container > .row > div.home_bottom_item {
  padding-left: 25px;
  padding-right: 25px;
  text-align: center;
}

@media (max-width: 991px) {
  #home-bottom > .container > .row > div.home_bottom_item {
    margin-bottom: 25px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

#home-bottom > .container > .row > div.home_bottom_item .home_bottom_image {
  margin-bottom: 20px;
}

#home-bottom > .container > .row > div.home_bottom_item .home_bottom_title {
  color: #2F2F32;
  margin-bottom: 10px;
  text-transform: uppercase;
}

#home-bottom > .container > .row > div.home_bottom_item .home_bottom_subtitle {
  color: #2F2F32;
  margin-bottom: 20px;
  padding: 0 20px;
}

#home-bottom > .container > .row > div.home_bottom_item .home_bottom_button {
  text-align: center;
}

#home-bottom > .container > .row > div.home_bottom_item .home_bottom_button > div {
  display: inline-block;
  padding: 12px 12px;
  border: 1px solid #FF0000;
  background: transparent;
  border-radius: 25px;
  width: 41px;
  position: relative;
  text-align: left;
  transition: all 0.3s linear;
}

#home-bottom > .container > .row > div.home_bottom_item .home_bottom_button > div:after {
  content: '\f178';
  font-family: 'fontAwesome';
  font-size: 13px;
  color: #FF0000;
  position: absolute;
  right: 50%;
  top: 50%;
  -webkit-transform: translateX(50%) translateY(-50%);
  transform: translateX(50%) translateY(-50%);
  transition: all 0.3s linear;
}

#home-bottom > .container > .row > div.home_bottom_item .home_bottom_button > div a {
  color: #FFFFFF;
  opacity: 0;
  white-space: nowrap;
}

#home-bottom > .container > .row > div.home_bottom_item .home_bottom_button > div:hover {
  background: #FF0000;
  width: 150px;
  padding: 12px 20px;
}

#home-bottom > .container > .row > div.home_bottom_item .home_bottom_button > div:hover:after {
  color: #FFFFFF;
  right: 25px;
}

#home-bottom > .container > .row > div.home_bottom_item .home_bottom_button > div:hover a {
  opacity: 1;
}

.innerpage_header {
  background: #95101A;
  padding: 50px 0;
}

.innerpage_header .innerpage_title {
  color: #FFFFFF;
  text-transform: uppercase;
  margin-bottom: 8px;
}

@media (max-width: 480px) {
  .innerpage_header .innerpage_title {
    text-align: center;
  }
}

@media (max-width: 480px) {
  .inner .text-editor-content {
    margin-top: 15px !important;
  }
}

.inner .text-editor-content .title-line {
  position: relative;
}

.inner .text-editor-content .title-line:before {
  content: '';
  background: #2F2F32;
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: -1;
}

@media (max-width: 480px) {
  .inner_section {
    display: block !important;
  }
  .inner_section span {
    display: block;
    margin: 0 auto !important;
  }
  .inner_section span:nth-child(2) {
    width: 100% !important;
    margin: 15px auto !important;
    text-align: center;
  }
}

#inner-banner .slide {
  width: 100%;
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

#breadcrumb {
  display: block;
}

@media (max-width: 480px) {
  #breadcrumb {
    text-align: center;
  }
}

#breadcrumb .text {
  color: #FFFFFF;
  text-transform: uppercase;
}

#breadcrumb .crumb {
  color: #FFFFFF;
  text-transform: uppercase;
}

#breadcrumb .crumb:after {
  content: '>';
  margin: 0 5px;
  color: #FFFFFF;
}

#breadcrumb .crumb:last-child:after {
  content: '';
  display: none;
}

#content {
  padding-bottom: 100px;
}

#content.home {
  padding-bottom: 0;
}

#content .page-content h1, #content .page-content h2, #content .page-content h3 {
  color: #004161;
  padding: 15px 0;
  margin: 15px 0 0;
}

#content .page-content h2 {
  font-size: 24px;
}

#content .page-content p {
  color: #000000;
  font-size: 12px;
  text-align: justify;
}

#content .page-content img {
  max-width: 100%;
  display: block;
}

#content .page-content .read-more {
  color: #004161;
  font-size: 13px;
  position: relative;
}

#content .page-content .read-more:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-color: #1ca7e0;
  top: 50%;
  left: 100%;
  margin-left: 5px;
  margin-top: -3px;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 3px solid #1ca7e0;
  border-left-color: inherit;
}

#content .page-content .left-content {
  background-color: #ffffff;
}

#content .page-content .left-content > *:last-child {
  margin-bottom: 30px;
}

#content .page-content .right-content {
  background-color: #F3F3F3;
}

#content .page-content .right-content > *:last-child {
  margin-bottom: 30px;
}

#content .bottom-content {
  margin-top: 30px;
}

#news-mini {
  border-top: 15px solid rgba(255, 255, 255, 0.1);
  border-bottom: 15px solid rgba(0, 0, 0, 0.06);
  padding: 20px;
  background-color: #1ca7e0;
  transition: all 0.5s ease-in-out;
  /*end of news*/
}

#news-mini:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

#news-mini > * {
  display: inline-block;
  vertical-align: middle;
  max-width: 98%;
}

#news-mini .title-cont {
  position: relative;
  border-right: 1px solid #0083B4;
  box-shadow: #16BCF9 1px 0 0 0;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  #news-mini .title-cont {
    width: 100%;
    border: none;
    box-shadow: none;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #0083B4;
    box-shadow: #16BCF9 0 1px 0 0;
    transition: all 0.5s ease-in-out;
  }
}

#news-mini .title-cont .title {
  font-size: 18px;
  color: white;
  margin-bottom: 10px;
}

@media (max-width: 991px) {
  #news-mini .title-cont .title {
    display: inline-block;
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  #news-mini .title-cont .title {
    display: inline-block;
  }
}

#news-mini .title-cont .link {
  color: #004161;
  display: inline-block;
  font-size: 13px;
  position: relative;
}

#news-mini .title-cont .link:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-color: white;
  top: 50%;
  left: 100%;
  margin-left: 5px;
  margin-top: -3px;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 3px solid white;
  border-left-color: inherit;
}

@media (max-width: 768px) {
  #news-mini .title-cont .link {
    float: right;
  }
}

#news-mini .news {
  transition: opacity 0.5s ease-in-out;
  border-right: 1px solid #0083B4;
  box-shadow: #16BCF9 1px 0 0 0;
}

@media (max-width: 991px) {
  #news-mini .news {
    border: none;
    box-shadow: none;
  }
}

#news-mini .news .slide:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

#news-mini .news .slide > * {
  display: inline-block;
  vertical-align: middle;
  max-width: 98%;
}

#news-mini .news .slide .date {
  color: #004161;
  text-align: center;
}

#news-mini .news .slide .date .day {
  font-size: 30px;
}

#news-mini .news .slide .date .month {
  font-size: 14px;
}

#news-mini .news .slide .desc .title {
  color: #004161;
  margin-bottom: 5px;
  text-transform: uppercase;
  display: inline-block;
  max-width: 98%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

#news-mini .news .slide .desc p {
  color: white;
  font-size: 13px;
  margin: 0;
}

#news-mini .news .slide .desc a {
  display: block;
}

#news-mini .news .slide:focus {
  outline: none;
}

#news-mini .slide-arrow:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

#news-mini .slide-arrow > * {
  display: inline-block;
  vertical-align: middle;
  max-width: 98%;
}

#news-mini .slide-arrow .slide-left, #news-mini .slide-arrow .slide-right {
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.5s ease-in-out;
}

#news-mini .slide-arrow .slide-left:hover, #news-mini .slide-arrow .slide-right:hover {
  color: white;
}

#news-mini .slide-arrow .slide-left:hover, #news-mini .slide-arrow .slide-right:hover {
  color: white;
}

#news-mini .slide-arrow .slide-right {
  margin-left: 5px;
}

#newsletter-wrapper {
  background: #FF0000;
  padding-top: 25px;
  padding-bottom: 28px;
  transition: all 0.3s linear;
}

#newsletter-wrapper.showing {
  padding-bottom: 0;
}

#newsletter-wrapper.showing #newsletter {
  padding-bottom: 17px;
}

#newsletter-wrapper.showing #back_to_top {
  margin-top: 0;
}

#newsletter-wrapper > .container {
  position: relative;
}

@media (max-width: 991px) {
  #newsletter-wrapper {
    text-align: center;
  }
}

#newsletter {
  transition: all 0.3s linear;
}

#newsletter.showing {
  padding-bottom: 17px;
}

#newsletter .newsletter-title {
  color: #FFFFFF;
  text-transform: uppercase;
  line-height: 30px;
  letter-spacing: 1px;
  padding-left: 50px;
}

@media (max-width: 991px) {
  #newsletter .newsletter-title {
    padding-left: 15px;
    text-align: center;
  }
}

#newsletter .newsletter-title .newsletter-subtitle {
  font-family: 'Gotham-Light';
  font-size: 12px;
}

@media (max-width: 480px) {
  #newsletter .newsletter-title .newsletter-subtitle {
    line-height: 20px;
  }
}

#newsletter .newsletter-input input {
  box-shadow: none;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #FFFFFF;
  width: 100%;
  background: transparent;
  color: #FFFFFF;
  height: 51px;
  padding: 12px 10px 12px;
  text-transform: uppercase;
}

#newsletter .newsletter-input input::-webkit-input-placeholder {
  color: #FFFFFF;
  font-family: 'Gotham-Light';
  font-size: 12px;
}

#newsletter .newsletter-input input::-moz-placeholder {
  color: #FFFFFF;
  font-family: 'Gotham-Light';
  font-size: 12px;
}

#newsletter .newsletter-input input:-moz-placeholder {
  color: #FFFFFF;
  font-family: 'Gotham-Light';
  font-size: 12px;
}

#newsletter .newsletter-input input:-ms-input-placeholder {
  color: #FFFFFF;
  font-family: 'Gotham-Light';
  font-size: 12px;
}

#newsletter .newsletter-button {
  text-align: center;
}

@media (max-width: 991px) {
  #newsletter .newsletter-button {
    margin-top: 20px;
  }
}

#newsletter .newsletter-button button {
  color: #FFFFFF;
  background: #AD131E;
  border: 1px solid #AD131E;
  text-transform: uppercase;
  letter-spacing: 4px;
  padding: 15px 17px;
  border-radius: 25px;
  text-align: center;
  width: 90%;
  transition: all 0.3s linear;
}

#newsletter .newsletter-button button:hover {
  background: #95101A;
}

#newsletter .newsletter-button button:focus {
  outline: none !important;
}

@media (max-width: 1280px) {
  #newsletter .newsletter-button button {
    font-size: 11px;
  }
}

#newsletter h3.title {
  color: #1ca7e0 !important;
  font-size: 20px;
  margin: 0 0 5px !important;
  padding: 0;
}

@media (max-width: 991px) {
  #newsletter h3.title {
    font-size: 17px;
  }
}

#newsletter .link {
  color: white;
  font-size: 13px;
  display: none;
  position: relative;
}

#newsletter .link:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-color: #1ca7e0;
  top: 50%;
  left: 100%;
  margin-left: 5px;
  margin-top: -3px;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 3px solid #1ca7e0;
  border-left-color: inherit;
}

#newsletter .form-group {
  display: none;
}

#newsletter .form-group .form-control {
  width: 100%;
  height: 34px;
  font-weight: normal;
}

#newsletter .form-group .form-control-feedback {
  width: auto;
  height: auto;
  margin: 10px;
}

#back_to_top {
  cursor: pointer;
  text-align: center;
  transition: all 0.3s linear;
  margin-top: -15px;
}

#back_to_top .totop_text {
  color: #FFFFFF;
}

#back_to_top .totop_text:after {
  content: '\f106';
  font-family: 'fontAwesome';
  font-size: 12px;
  color: #FFFFFF;
  display: block;
  padding-top: 5px;
}

@media (max-width: 991px) {
  #back_to_top {
    padding-bottom: 50px;
    display: inline-block;
    margin-top: 20px;
  }
}

.totop_image {
  position: absolute;
  bottom: -1px;
  right: 50px;
  cursor: pointer;
}

@media (max-width: 1280px) {
  .totop_image {
    right: 32px;
  }
}

@media (max-width: 991px) {
  .totop_image {
    right: 50%;
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
  }
}

.a-banner {
  margin-top: 30px;
}

#get-quote {
  margin-top: 30px;
}

#event-calendar {
  margin-top: 30px;
}

#home-video-cont {
  padding: 0;
  height: 279px;
}

#home-video-cont a {
  display: block;
  width: 100%;
}

#home-video-cont .vid {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

#home-customer-value {
  border-top: 15px solid rgba(255, 255, 255, 0.1);
  border-bottom: 15px solid rgba(0, 0, 0, 0.06);
  padding: 20px 0;
  background-color: #1ca7e0;
  transition: all 0.5s ease-in-out;
}

#home-customer-value .title {
  color: #004161;
}

#home-customer-value img {
  margin-bottom: 15px;
}

#home-customer-value p {
  color: white;
}

#home-any-last-words {
  margin: 30px 0;
  color: #1ca7e0;
}

#popUpsContainer {
  display: none;
}

.white-popup.popup {
  padding: 10px;
}

footer #footer-content {
  background: #4B1116;
  padding: 0 20px 20px;
}

@media (max-width: 991px) {
  footer #footer-content {
    padding: 0 0 20px;
  }
}

footer #footer-content.inner {
  padding-top: 0;
}

footer #footer-content.inner .top-footer {
  display: none;
  padding-top: 30px;
}

@media (max-width: 991px) {
  footer #footer-content.inner .top-footer #social-icon {
    margin-bottom: 65px;
  }
}

footer #footer-content.inner .bottom-footer #back_to_top2 {
  display: inline-block;
  position: absolute;
  top: -79px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  cursor: pointer;
}

footer #footer-content.inner .bottom-footer #back_to_top2 .totop_text {
  color: #2F2F32;
  margin-bottom: -2px;
  text-align: center;
  transition: all 0.3s linear;
}

footer #footer-content.inner .bottom-footer #back_to_top2 .totop_text:after {
  content: '\f106';
  font-family: 'fontAwesome';
  font-size: 12px;
  color: #2F2F32;
  display: block;
  padding-top: 5px;
  transition: all 0.3s linear;
}

footer #footer-content.inner .bottom-footer #back_to_top2 .totop_text.white {
  color: #FFFFFF;
}

footer #footer-content.inner .bottom-footer #back_to_top2 .totop_text.white:after {
  color: #FFFFFF;
}

footer #footer-content.inner .bottom-footer #back_to_top2 .totop_image {
  position: relative;
  right: auto;
  top: auto;
  left: auto;
  bottom: auto;
}

@media (max-width: 991px) {
  footer #footer-content.inner .bottom-footer #back_to_top2 .totop_image {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@media (max-width: 480px) {
  footer #footer-content.inner .bottom-footer #back_to_top2 {
    left: 0;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@media (max-width: 991px) {
  footer #footer-content.inner .bottom-footer .sitemap {
    right: 0;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

footer #footer-content h4 {
  color: #FFFFFF;
  text-transform: uppercase;
  margin-bottom: 10px;
  letter-spacing: 1px;
}

@media (max-width: 991px) {
  footer #footer-content h4 {
    text-align: center;
  }
}

.bottom-footer {
  padding-top: 15px;
  position: relative;
}

.bottom-footer #back_to_top2 {
  display: none;
}

.bottom-footer a {
  color: #757575;
}

.bottom-footer:before {
  content: '';
  background: #370C10;
  position: absolute;
  top: 0;
  left: -25px;
  right: -25px;
  height: 1px;
}

@media (max-width: 991px) {
  .bottom-footer:before {
    left: 25px;
    right: 25px;
  }
}

.bottom-footer .page_footer_text {
  color: #757575;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.bottom-footer .page_footer_text.page_footer_text_left {
  float: left;
  text-align: left;
}

@media (max-width: 991px) {
  .bottom-footer .page_footer_text.page_footer_text_left {
    text-align: center;
    float: none;
  }
}

.bottom-footer .page_footer_text.page_footer_text_right {
  float: right;
  text-align: right;
}

@media (max-width: 991px) {
  .bottom-footer .page_footer_text.page_footer_text_right {
    text-align: center;
    float: none;
  }
}

.bottom-footer .sitemap {
  position: absolute;
  top: -28px;
  right: 0;
  background: #95101A;
  padding: 7px 12px 5px;
  border-radius: 10px 10px 0 0;
  color: #FFFFFF;
  text-transform: uppercase;
}

.bottom-footer .sitemap a, .bottom-footer .sitemap span {
  color: #FFFFFF;
  white-space: nowrap;
  cursor: pointer;
}

.bottom-footer .sitemap a strong, .bottom-footer .sitemap span strong {
  padding-right: 10px;
}

.bottom-footer .sitemap a:after, .bottom-footer .sitemap span:after {
  content: '\f13a';
  font-family: 'fontAwesome';
  font-size: 12px;
  color: #FFFFFF;
  display: inline-block;
  transition: all 0.3s linear;
  transform-origin: center;
  -ms-transform-origin: center;
  -webkit-transform-origin: center;
}

.bottom-footer .sitemap a.showing:after, .bottom-footer .sitemap span.showing:after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

@media (max-width: 991px) {
  .bottom-footer .sitemap {
    right: 50%;
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
  }
}

.top-footer {
  padding-bottom: 50px;
  background: #4B1116;
  display: none;
  padding-top: 30px;
}

@media (max-width: 991px) {
  .top-footer {
    padding-bottom: 40px;
  }
}

.top-footer .footer-nav-1 li {
  width: calc(100% / 3.1);
}

.top-footer .footer-nav-1 li a {
  color: #B8B8B8;
  transition: all 0.3s linear;
}

.top-footer .footer-nav-2 li {
  width: calc(100% / 2.1);
}

.top-footer .footer-nav-2 li a {
  color: #B8B8B8;
  transition: all 0.3s linear;
}

@media (max-width: 991px) {
  .top-footer .footer-nav-1, .top-footer .footer-nav-2 {
    margin-bottom: 20px;
  }
}

.top-footer .footer-nav-1 h4, .top-footer .footer-nav-2 h4 {
  color: #FFFFFF;
  text-transform: uppercase;
  margin-bottom: 10px;
  letter-spacing: 1px;
}

@media (max-width: 991px) {
  .top-footer .footer-nav-1 h4, .top-footer .footer-nav-2 h4 {
    text-align: center;
  }
}

.top-footer .footer-nav-1 li, .top-footer .footer-nav-2 li {
  transition: all 0.3s linear;
  display: inline-block;
  color: #B8B8B8;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.top-footer .footer-nav-1 li:hover, .top-footer .footer-nav-2 li:hover {
  color: #FF0000;
}

.top-footer .footer-nav-1 li:hover a, .top-footer .footer-nav-2 li:hover a {
  color: #FF0000;
}

@media (max-width: 991px) {
  .top-footer .footer-nav-1 li, .top-footer .footer-nav-2 li {
    text-align: center;
    margin-bottom: 14px;
  }
}

.top-footer p {
  font-size: 10px;
  color: white;
  margin: 0;
  line-height: 1.4;
}

.top-footer .footer-logo img {
  margin-bottom: 10px;
}

@media (max-width: 991px) {
  .top-footer .footer-logo {
    text-align: center;
    margin-bottom: 20px;
  }
}

.top-footer .hotline {
  margin-top: 15px;
}

.top-footer .hotline i {
  color: #1ca7e0;
  vertical-align: middle;
}

.top-footer .hotline .phone-number {
  display: inline-block;
  color: white;
  vertical-align: middle;
  margin: 0;
}

.top-footer .hotline .phone-number > a {
  color: white;
}

.top-footer .quick-nav a {
  display: block;
  color: white;
  margin-bottom: 5px;
  font-size: 12px;
}

.top-footer .quick-nav a:after {
  left: 0;
}

.top-footer .quick-nav a:hover {
  text-decoration: underline;
}

.top-footer .quick-nav a:hover:after {
  left: 100%;
}

.top-footer .quick-nav a i {
  color: #1ca7e0;
  padding: 0 5px;
}

.top-footer .address {
  margin-bottom: 10px;
}

#social-icon {
  margin-bottom: 5px;
}

@media (max-width: 991px) {
  #social-icon {
    text-align: center;
  }
}

#social-icon ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-block;
}

#social-icon ul li {
  font-size: 16px;
  display: inline-block;
}

#social-icon ul li a {
  color: #FFFFFF;
  padding: 0 5px;
  border-radius: 100px;
  border: 1px solid #757575;
  width: 40px;
  height: 40px;
  line-height: 38px;
  text-align: center;
  margin-right: 10px;
  background: transparent;
  transition: all 0.3s linear;
}

#social-icon ul li a i {
  font-size: 15px;
}

#social-icon ul li a:hover {
  background: #FF0000;
  border: 1px solid #FF0000;
}

/*inner page*/
#inner {
  margin-top: -60px;
  background-color: white;
}

#inner.no-banner {
  margin-top: 0;
}

#inner #newsletter {
  margin: 0 -15px 30px;
  display: block;
  min-height: 100px;
  width: auto;
}

#searchBar {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  text-align: center;
  padding-top: 20px;
}

#searchBar input {
  width: 100%;
  height: 100%;
  font-size: 26px;
  text-align: center;
  color: #333;
  border: 0;
  box-shadow: none;
}

.editor-mode [data-block-editable] {
  outline: dashed #7f7f7f 1px;
  display: block !important;
}

.editor-mode #landing_page .landing_page_navigation > div .landing_page_navigation_item .landing_page_navigation_item_content {
  position: relative !important;
}

.editor-mode .banner_extras {
  position: relative !important;
}

.editor-mode .imgbg {
  opacity: 1 !important;
}

.editor-mode .top-footer {
  display: block !important;
}

@media (min-width: 768px) {
  #divChangeViews {
    display: none;
  }
}

[data-mh] {
  transition: height 0.3s;
}

/*************************************** MAINPLACE ***************************************/
#landing_page_background {
  width: 100%;
  height: 100vh;
  background: linear-gradient(to bottom, rgba(206, 0, 0, 0.8), rgba(47, 47, 50, 0.8)), url("../../img/mainplace/landing/landing_bg.png") bottom center no-repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: -1;
}

#landing_page {
  position: relative;
  width: 100%;
}

#landing_page .landing_page_logo {
  text-align: center;
  margin-top: 40px;
}

#landing_page .landing_page_title {
  text-align: center;
  margin-top: 50px;
}

#landing_page .landing_page_title .landing_page_title_1 {
  color: #FFFFFF;
  font-family: 'Gotham-Bold';
}

#landing_page .landing_page_title .landing_page_title_2 {
  color: #FFFFFF;
  font-family: 'Gotham-Bold';
  margin-bottom: 15px;
}

#landing_page .landing_page_title .landing_page_title_3 {
  color: #FFFFFF;
  font-family: 'Gotham-Bold';
}

#landing_page .landing_page_navigation {
  margin-top: 40px;
  margin-bottom: 30px;
}

#landing_page .landing_page_navigation > div .landing_page_navigation_item {
  text-align: center;
  position: relative;
}

#landing_page .landing_page_navigation > div .landing_page_navigation_item:first-child > div {
  float: right;
}

#landing_page .landing_page_navigation > div .landing_page_navigation_item:last-child > div {
  float: left;
}

@media (max-width: 991px) {
  #landing_page .landing_page_navigation > div .landing_page_navigation_item > div {
    float: none !important;
  }
  #landing_page .landing_page_navigation > div .landing_page_navigation_item:first-child {
    margin-bottom: 40px;
  }
}

#landing_page .landing_page_navigation > div .landing_page_navigation_item > div {
  display: inline-block;
  position: relative;
}

#landing_page .landing_page_navigation > div .landing_page_navigation_item > div:hover .landing_page_navigation_item_image > div:before {
  opacity: 0;
  transition: all 0.3s linear;
}

#landing_page .landing_page_navigation > div .landing_page_navigation_item > div:hover .landing_page_navigation_item_link > div {
  background: #CE0000 !important;
  border: solid 1px #CE0000 !important;
}

#landing_page .landing_page_navigation > div .landing_page_navigation_item > div:hover .landing_page_navigation_item_link > div a:after {
  color: #CE0000 !important;
  background: #FFFFFF !important;
  border: solid 1px #CE0000 !important;
}

#landing_page .landing_page_navigation > div .landing_page_navigation_item .landing_page_navigation_item_image > div {
  display: inline-block;
  position: relative;
}

#landing_page .landing_page_navigation > div .landing_page_navigation_item .landing_page_navigation_item_image > div:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(47, 47, 50, 0.45);
  opacity: 1;
  z-index: 2;
  border-radius: 15px;
  transition: all 0.3s linear;
}

@media (max-width: 480px) {
  #landing_page .landing_page_navigation > div .landing_page_navigation_item .landing_page_navigation_item_image > div:before {
    border-radius: 10px;
  }
}

#landing_page .landing_page_navigation > div .landing_page_navigation_item .landing_page_navigation_item_image > div img {
  position: relative;
  z-index: 1;
}

#landing_page .landing_page_navigation > div .landing_page_navigation_item .landing_page_navigation_item_content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
}

#landing_page .landing_page_navigation > div .landing_page_navigation_item .landing_page_navigation_item_content .landing_page_navigation_item_top {
  height: calc(100% - 90px);
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

#landing_page .landing_page_navigation > div .landing_page_navigation_item .landing_page_navigation_item_content .landing_page_navigation_item_bottom .landing_page_navigation_item_link {
  display: inline-block;
  width: 65%;
}

@media (max-width: 480px) {
  #landing_page .landing_page_navigation > div .landing_page_navigation_item .landing_page_navigation_item_content .landing_page_navigation_item_bottom .landing_page_navigation_item_link {
    width: 85%;
  }
}

#landing_page .landing_page_navigation > div .landing_page_navigation_item .landing_page_navigation_item_content .landing_page_navigation_item_bottom .landing_page_navigation_item_link:hover > div a:after {
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px);
}

#landing_page .landing_page_navigation > div .landing_page_navigation_item .landing_page_navigation_item_content .landing_page_navigation_item_bottom .landing_page_navigation_item_link div {
  background: transparent;
  color: #FFFFFF;
  padding: 12px;
  border-radius: 50px;
  border: 1px solid #FFFFFF;
  transition: all 0.3s linear;
}

#landing_page .landing_page_navigation > div .landing_page_navigation_item .landing_page_navigation_item_content .landing_page_navigation_item_bottom .landing_page_navigation_item_link div a {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 100%;
  color: #FFFFFF;
  margin-left: 10px;
}

#landing_page .landing_page_navigation > div .landing_page_navigation_item .landing_page_navigation_item_content .landing_page_navigation_item_bottom .landing_page_navigation_item_link div a:after {
  content: '\f105';
  font-family: 'fontAwesome';
  color: #FFFFFF;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border: solid 1px #FFFFFF;
  border-radius: 50px;
  padding: 0 12px;
  transition: all 0.3s ease-in;
  -webkit-transform: perspective(1px) translateX(-35px) translateZ(0);
  transform: perspective(1px) translateX(-35px) translateZ(0);
}

#landing_page_footer {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

#landing_page_footer .landing_page_bottom {
  text-align: center;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

#landing_page_footer .landing_page_bottom .landing_page_footer_text {
  color: #757575;
  text-transform: uppercase;
  width: calc(100% / 3);
}

#landing_page_footer .landing_page_bottom .landing_page_footer_text:first-child {
  text-align: left;
}

#landing_page_footer .landing_page_bottom .landing_page_footer_text:last-child {
  text-align: right;
}

#landing_page_footer .landing_page_bottom .landing_page_footer_image {
  width: calc(100% / 3);
}

.directory_search {
  padding: 30px 0;
}

.directory_search .directory_search_top #directory_search_list {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 10px 0;
}

.directory_search .directory_search_top #directory_search_list .nav-item {
  background: transparent;
}

.directory_search .directory_search_top #directory_search_list .nav-item:last-child .nav-link:after {
  display: none;
}

.directory_search .directory_search_top #directory_search_list .nav-item .nav-link {
  color: #2F2F32;
  transition: all 0.3s linear;
  text-transform: uppercase;
  padding: 0 30px;
  background: transparent;
}

@media (max-width: 480px) {
  .directory_search .directory_search_top #directory_search_list .nav-item .nav-link {
    padding: 0 15px;
  }
}

.directory_search .directory_search_top #directory_search_list .nav-item .nav-link:after {
  content: '';
  background: #CE0000;
  width: 2px;
  height: 100%;
  position: absolute;
  right: 0;
}

.directory_search .directory_search_top #directory_search_list .nav-item .nav-link:hover {
  color: #B8B8B8;
  background: transparent;
}

.directory_search .directory_search_top #directory_search_list .nav-item.active .nav-link {
  color: #CE0000;
}

.directory_search .directory_search_bottom {
  padding: 30px 0;
}

.directory_search .directory_search_bottom .store_listing_categories {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 90%;
  margin: 0 auto;
}

.directory_search .directory_search_bottom .store_listing_categories .store_listing_category {
  width: calc(100% / 7);
}

@media (max-width: 991px) {
  .directory_search .directory_search_bottom .store_listing_categories .store_listing_category {
    width: calc(100% / 6);
  }
}

@media (max-width: 480px) {
  .directory_search .directory_search_bottom .store_listing_categories .store_listing_category {
    width: calc(100% / 3);
  }
}

@media (max-width: 325px) {
  .directory_search .directory_search_bottom .store_listing_categories .store_listing_category {
    width: calc(100% / 2);
  }
}

.directory_search .directory_search_bottom .store_listing_categories .store_listing_category > div {
  width: 90px;
  min-width: 90px;
  height: 90px;
  background: #FF0000;
  border-radius: 100px;
  text-align: center;
  margin: 10px auto;
  line-height: 85px;
  cursor: pointer;
  transition: all 0.3s linear;
  position: relative;
}

.directory_search .directory_search_bottom .store_listing_categories .store_listing_category .store_listing_appended {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 100%;
  line-height: 20px;
  width: 150px;
  padding: 5px 10px;
  display: none;
  z-index: 1;
  background: #AD131E;
  color: #FFFFFF;
  font-size: 11px;
  border-radius: 5px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.directory_search .directory_search_bottom .store_listing_categories .store_listing_category.active > div {
  background: #AD131E;
}

.directory_search .directory_search_bottom .store_listing_categories .store_listing_category:hover > div {
  background: #CE0000;
}

.directory_search .directory_search_bottom .store_listing_list {
  padding-top: 60px;
}

.directory_search .directory_search_bottom .store_listing_list .store_listing_section {
  margin-bottom: 40px;
  display: none;
}

.directory_search .directory_search_bottom .store_listing_list .store_listing_section.open {
  display: block;
}

.directory_search .directory_search_bottom .store_listing_list .store_listing_section .store_listing_title {
  color: #AD131E;
  width: 90%;
  margin: 0 auto 20px;
}

.directory_search .directory_search_bottom .store_listing_list .store_listing_section .store_listing_items .store_listing_item {
  background: transparent;
  transition: all 0.3s linear;
}

.directory_search .directory_search_bottom .store_listing_list .store_listing_section .store_listing_items .store_listing_item:hover {
  background: #fde8e6;
}

.directory_search .directory_search_bottom .store_listing_list .store_listing_section .store_listing_items .store_listing_item:hover .store_name, .directory_search .directory_search_bottom .store_listing_list .store_listing_section .store_listing_items .store_listing_item:hover .store_location {
  text-decoration: none;
  color: #EA0005;
}

.directory_search .directory_search_bottom .store_listing_list .store_listing_section .store_listing_items .store_listing_item a {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  padding: 5px 0;
  width: 90%;
  margin: 0 auto;
  transition: all 0.3s linear;
}

.directory_search .directory_search_bottom .store_listing_list .store_listing_section .store_listing_items .store_listing_item a .store_name {
  color: #2F2F32;
  transition: all 0.3s linear;
}

@media (max-width: 480px) {
  .directory_search .directory_search_bottom .store_listing_list .store_listing_section .store_listing_items .store_listing_item a .store_name {
    width: 70%;
  }
}

.directory_search .directory_search_bottom .store_listing_list .store_listing_section .store_listing_items .store_listing_item a .store_location {
  color: #2F2F32;
  transition: all 0.3s linear;
}

@media (max-width: 480px) {
  .directory_search .directory_search_bottom .store_listing_list .store_listing_section .store_listing_items .store_listing_item a .store_location {
    width: 30%;
    text-align: right;
  }
}

.store-details {
  padding: 20px 0;
}

.store-details .store-details-top {
  border-top: 2px solid #F4F4F4;
  border-bottom: 2px solid #F4F4F4;
  margin-bottom: 20px;
}

.store-details .store-details-top .store-details-left {
  padding: 0;
  border-right: 2px solid #F4F4F4;
}

@media (max-width: 991px) {
  .store-details .store-details-top .store-details-left {
    border: none;
  }
}

.store-details .store-details-top .store-details-left .store-details-logo {
  border-bottom: 2px solid #F4F4F4;
  text-align: center;
  padding: 25px;
  margin: 0 auto;
}

@media (max-width: 991px) {
  .store-details .store-details-top .store-details-left .store-details-logo {
    border-left: 2px solid #F4F4F4;
    border-right: 2px solid #F4F4F4;
  }
}

.store-details .store-details-top .store-details-left .store-details-logo img {
  max-height: 140px;
}

.store-details .store-details-top .store-details-left .store-details-description {
  padding: 15px 25px;
}

.store-details .store-details-top .store-details-left .store-details-description .store-details-info-title {
  color: #EA0005;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.store-details .store-details-top .store-details-left .store-details-description .store-details-info-title i {
  font-size: 16px;
  color: #EA0005;
}

.store-details .store-details-top .store-details-left .store-details-description .store-details-info-desc {
  font-size: 14px;
  color: #2F2F32;
  margin-bottom: 15px;
}

.store-details .store-details-top .store-details-left .store-details-description .store-details-info-extras {
  margin-bottom: 10px;
}

.store-details .store-details-top .store-details-left .store-details-description .store-details-info-extras .store-details-info-extras-content i {
  width: 25px;
  color: #EA0005;
  font-size: 14px;
}

.store-details .store-details-top .store-details-left .store-details-description .store-details-info-extras .store-details-info-extras-content span {
  font-size: 14px;
  color: #2F2F32;
}

.store-details .store-details-top .store-details-left .store-details-socialmedia {
  text-align: left;
  padding: 10px;
}

.store-details .store-details-top .store-details-left .store-details-socialmedia a {
  color: #EA0005;
  padding: 0 5px;
  border-radius: 100px;
  border: 2px solid #F4F4F4;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  background: #FFFFFF;
  transition: all 0.3s linear;
  margin: 0 5px;
  transition: all 0.3s linear;
}

.store-details .store-details-top .store-details-left .store-details-socialmedia a i {
  width: 25px;
  color: #EA0005;
  font-size: 14px;
  transition: all 0.3s linear;
}

.store-details .store-details-top .store-details-left .store-details-socialmedia a:hover {
  background: #EA0005;
  border-color: #EA0005;
}

.store-details .store-details-top .store-details-left .store-details-socialmedia a:hover i {
  color: #FFFFFF;
}

.store-details .store-details-top .store-details-right {
  padding: 0;
}

.store-details .store-details-top .store-details-right > .row {
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: start;
  align-items: flex-start;
}

@media (max-width: 991px) {
  .store-details .store-details-top .store-details-right > .row {
    height: auto;
    display: block;
  }
}

.store-details .store-details-top .store-details-right .store-main-image .store-main-image-listing {
  padding: 10px;
  margin-bottom: 0 !important;
  opacity: 0;
}

.store-details .store-details-top .store-details-right .store-side-image .store-side-image-listing {
  padding: 10px;
  height: 100%;
  margin-bottom: 0 !important;
  opacity: 0;
  font-size: 0;
}

.store-details .store-details-top .store-details-right .store-side-image .store-side-image-listing img {
  cursor: pointer;
}

.store-details .store-details-top .store-details-right .store-side-image .store-side-image-listing:before {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  /* vertical alignment of the inline element */
  height: 100%;
}

@media (max-width: 991px) {
  .store-details .store-details-top .store-details-right .store-side-image .store-side-image-listing:before {
    display: none;
  }
}

.store-details .store-details-top .store-details-right .store-side-image .store-side-image-listing .slick-list {
  display: inline-block;
  vertical-align: middle;
  padding: 0 !important;
}

@media (max-width: 991px) {
  .store-details .store-details-top .store-details-right .store-side-image .store-side-image-listing .slick-list {
    display: block;
    padding: 0 15px !important;
  }
}

.store-details .store-details-top .store-details-right .store-side-image .store-side-image-listing .slick-arrow:before {
  font-family: 'fontAwesome';
  transition: all 0.3s linear;
}

.store-details .store-details-top .store-details-right .store-side-image .store-side-image-listing .slick-arrow.slick-prev {
  top: 5%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

@media (max-width: 991px) {
  .store-details .store-details-top .store-details-right .store-side-image .store-side-image-listing .slick-arrow.slick-prev {
    top: 50%;
    left: 0;
    -webkit-transform: translateX(0) translateY(-50%);
    transform: translateX(0) translateY(-50%);
  }
}

.store-details .store-details-top .store-details-right .store-side-image .store-side-image-listing .slick-arrow.slick-prev:before {
  content: '\f106';
  color: #757575;
  opacity: 1 !important;
}

@media (max-width: 991px) {
  .store-details .store-details-top .store-details-right .store-side-image .store-side-image-listing .slick-arrow.slick-prev:before {
    content: '\f104';
  }
}

.store-details .store-details-top .store-details-right .store-side-image .store-side-image-listing .slick-arrow.slick-next {
  bottom: 5%;
  top: auto;
  right: 50%;
  -webkit-transform: translateX(50%);
  transform: translateX(50%);
}

@media (max-width: 991px) {
  .store-details .store-details-top .store-details-right .store-side-image .store-side-image-listing .slick-arrow.slick-next {
    top: 50%;
    bottom: auto;
    right: 0;
    -webkit-transform: translateX(0) translateY(-50%);
    transform: translateX(0) translateY(-50%);
  }
}

.store-details .store-details-top .store-details-right .store-side-image .store-side-image-listing .slick-arrow.slick-next:before {
  content: '\f107';
  color: #757575;
  opacity: 1 !important;
}

@media (max-width: 991px) {
  .store-details .store-details-top .store-details-right .store-side-image .store-side-image-listing .slick-arrow.slick-next:before {
    content: '\f105';
  }
}

.store-details .store-details-top .store-details-right .store-side-image .store-side-image-listing .slick-arrow:hover:before {
  color: #EA0005 !important;
}

.store-details .store-details-top .store-details-right .store-side-image .store-side-image-listing img {
  margin: 15px;
}

.store-details .store-details-floorplan {
  padding-bottom: 50px;
  border-bottom: 2px solid #F4F4F4;
}

.store-details .store-details-floorplan .store-details-floorplan-title {
  color: #2F2F32;
  text-transform: uppercase;
  padding: 15px 0;
  background: #fde8e6;
  margin-bottom: 20px;
  text-align: center;
}

.store-details .store-details-floorplan .store-details-floorplan-image {
  margin: 0 auto;
  text-align: center;
}

.store-details .store-details-floorplan .store-details-floorplan-image a {
  outline: none !important;
}

.store-details .store-details-floorplan .store-details-floorplan-image a:focus {
  outline: none !important;
}

.store-details .store-details-floorplan .store-details-floorplan-image img {
  outline: none !important;
  max-width: 100%;
  width: 100%;
}

.store-details .store-details-floorplan .store-details-floorplan-image img:focus {
  outline: none !important;
}

.store-details .store-details-related {
  padding: 20px 0;
}

.store-details .store-details-related .store-details-related-title {
  color: #2F2F32;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.store-details .store-details-related .store-details-related-title span {
  color: #EA0005;
}

.store-details .store-details-related .store-details-related-listing {
  margin: 0 -10px;
}

.store-details .store-details-related .store-details-related-listing .store-details-related-list-wrapper {
  margin: 10px;
  padding: 0;
  box-shadow: 0 -1px 7px 0 rgba(0, 0, 0, 0.25);
}

.store-details .store-details-related .store-details-related-listing .store-details-related-list-wrapper a {
  width: 100%;
}

.store-details .store-details-related .store-details-related-listing .store-details-related-list {
  padding: 25px;
}

.store-details .store-details-related .store-details-related-listing .store-details-related-list .store-details-related-top {
  margin-bottom: 25px;
}

.store-details .store-details-related .store-details-related-listing .store-details-related-list .store-details-related-top .store-details-related-top-logo {
  font-size: 0;
}

.store-details .store-details-related .store-details-related-listing .store-details-related-list .store-details-related-top .store-details-related-top-logo:before {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  /* vertical alignment of the inline element */
  height: 100%;
}

.store-details .store-details-related .store-details-related-listing .store-details-related-list .store-details-related-top .store-details-related-top-logo img {
  display: inline-block;
  vertical-align: middle;
  /* vertical alignment of the inline element */
}

.store-details .store-details-related .store-details-related-listing .store-details-related-list .store-details-related-top .store-details-related-top-title {
  font-size: 0;
  padding-left: 0;
}

.store-details .store-details-related .store-details-related-listing .store-details-related-list .store-details-related-top .store-details-related-top-title:before {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  /* vertical alignment of the inline element */
  height: 100%;
}

.store-details .store-details-related .store-details-related-listing .store-details-related-list .store-details-related-top .store-details-related-top-title .wrap {
  display: inline-block;
  vertical-align: middle;
}

.store-details .store-details-related .store-details-related-listing .store-details-related-list .store-details-related-top .store-details-related-top-title .wrap .title {
  color: #EA0005;
  text-transform: uppercase;
  margin-bottom: 8px;
  letter-spacing: 1px;
}

.store-details .store-details-related .store-details-related-listing .store-details-related-list .store-details-related-top .store-details-related-top-title .wrap .category {
  color: #FFFFFF;
  background: #EA0005;
  padding: 5px 12px;
  border-radius: 25px;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 10px;
}

.store-details .store-details-related .store-details-related-listing .store-details-related-list .store-details-related-image {
  display: table;
  width: 100%;
  margin-bottom: 20px;
}

.store-details .store-details-related .store-details-related-listing .store-details-related-list .store-details-related-image .image {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  position: relative;
  text-align: center;
}

.store-details .store-details-related .store-details-related-listing .store-details-related-list .store-details-related-image img {
  margin: 0 auto;
}

.store-details .store-details-related .store-details-related-listing .store-details-related-list .store-details-related-extras .store-details-related-extras-content {
  margin-bottom: 10px;
}

.store-details .store-details-related .store-details-related-listing .store-details-related-list .store-details-related-extras .store-details-related-extras-content i {
  color: #CE0000;
  width: 25px;
  font-size: 16px;
}

.store-details .store-details-related .store-details-related-listing .store-details-related-list .store-details-related-extras .store-details-related-extras-content span {
  color: #2F2F32;
}

.store-details .store-details-related .store-details-related-listing .slick-prev {
  width: 30px;
  height: 30px;
  background: #FFFFFF;
  border-radius: 50px;
  border: 1px solid #FF0000;
  text-align: center;
  z-index: 1;
  transition: all linear 0.3s;
  left: -35px;
}

.store-details .store-details-related .store-details-related-listing .slick-prev:before {
  content: '\f053';
  font-family: 'fontAwesome';
  font-size: 9px;
  color: #FF0000;
  line-height: 28px;
  padding-right: 3px;
  opacity: 1 !important;
  transition: all linear 0.3s;
}

.store-details .store-details-related .store-details-related-listing .slick-prev:hover {
  background: #FF0000;
}

.store-details .store-details-related .store-details-related-listing .slick-prev:hover:before {
  color: #FFFFFF;
}

@media (max-width: 1280px) {
  .store-details .store-details-related .store-details-related-listing .slick-prev {
    left: -5px;
  }
}

.store-details .store-details-related .store-details-related-listing .slick-next {
  width: 30px;
  height: 30px;
  background: #FFFFFF;
  border-radius: 50px;
  border: 1px solid #FF0000;
  text-align: center;
  z-index: 1;
  transition: all linear 0.3s;
  right: -35px;
}

.store-details .store-details-related .store-details-related-listing .slick-next:before {
  content: '\f054';
  font-family: 'fontAwesome';
  font-size: 9px;
  color: #FF0000;
  line-height: 28px;
  padding-left: 3px;
  opacity: 1 !important;
  transition: all linear 0.3s;
}

.store-details .store-details-related .store-details-related-listing .slick-next:hover {
  background: #FF0000;
}

.store-details .store-details-related .store-details-related-listing .slick-next:hover:before {
  color: #FFFFFF;
}

@media (max-width: 1280px) {
  .store-details .store-details-related .store-details-related-listing .slick-next {
    right: -5px;
  }
}

.promotion_details {
  padding: 40px 0;
}

.promotion_details .promotion_banner .promotion_banner_list {
  opacity: 0;
}

.promotion_details .promotion_title {
  font-family: 'Exo-Black';
  font-size: 40px;
  color: #EA0407;
  text-transform: uppercase;
  margin: 30px 0;
}

.promotion_details .promotion_descriptions {
  margin-bottom: 30px;
}

.promotion_details .promotion_descriptions .promotion_details_category {
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
}

@media (max-width: 991px) {
  .promotion_details .promotion_descriptions .promotion_details_category {
    display: block;
    margin-bottom: 20px;
  }
}

.promotion_details .promotion_descriptions .promotion_details_category span {
  background: #FF0000;
  color: #FFFFFF;
  font-family: 'Exo-Regular';
  font-size: 12px;
  padding: 5px 20px;
  border-radius: 25px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.promotion_details .promotion_descriptions .promotion_details_date, .promotion_details .promotion_descriptions .promotion_details_location {
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
}

@media (max-width: 480px) {
  .promotion_details .promotion_descriptions .promotion_details_date, .promotion_details .promotion_descriptions .promotion_details_location {
    margin-bottom: 5px;
  }
}

.promotion_details .promotion_descriptions .promotion_details_date i, .promotion_details .promotion_descriptions .promotion_details_location i {
  color: #CE0000;
  width: 10px;
}

.promotion_details .promotion_descriptions .promotion_details_date span, .promotion_details .promotion_descriptions .promotion_details_location span {
  font-family: 'Gotham-Bold';
  font-size: 14px;
  color: #2F2F32;
  text-transform: uppercase;
}

@media (max-width: 480px) {
  .promotion_details .promotion_descriptions .promotion_details_date span, .promotion_details .promotion_descriptions .promotion_details_location span {
    font-size: 13px;
  }
}

.promotion_details .promotion_descriptions .promotion_details_date span.label, .promotion_details .promotion_descriptions .promotion_details_location span.label {
  color: #CE0000;
}

.promotion_details .promotion_content {
  font-family: 'Gotham-Book';
  font-size: 14px;
  color: #2F2F32;
  margin-bottom: 25px;
  line-height: 20px;
}

.promotion_details .promotion_button a {
  font-family: 'Exo-Bold';
  font-size: 14px;
  text-transform: uppercase;
  color: #FFFFFF;
  background: #AD131E;
  padding: 15px 35px;
  letter-spacing: 2px;
  border-radius: 50px;
  transition: all 0.3s linear;
}

.promotion_details .promotion_button a:hover {
  color: #FFFFFF;
  background: #EA0407;
}

.promotion_details .promotion_button a i {
  padding-right: 10px;
  color: #FFFFFF;
}

.pagination {
  display: block;
  text-align: center;
}

.pagination .pagination_text a {
  color: #757575;
  letter-spacing: 1px;
}

.pagination .pagination_number_wrapper {
  border-radius: 30px;
  border: 1px solid #E2E2E2;
  padding: 10px 20px;
  margin: 0 15px;
}

.pagination .pagination_number_wrapper .pagination_number {
  margin: 0 5px;
}

.pagination .pagination_number_wrapper .pagination_number a {
  color: #2F2F32;
  border-radius: 50px;
  background: transparent;
  width: 25px;
  height: 25px;
  padding: 5px 0;
  text-align: center;
}

.pagination .pagination_number_wrapper .pagination_number.active a {
  background: #EA0005;
  color: #FFFFFF;
}

@media (max-width: 480px) {
  .promotion_listing_sorting {
    text-align: center;
  }
}

.promotion_listing_sorting form {
  margin-bottom: 20px;
}

.promotion_listing_sorting form .form_input {
  display: inline-block;
  margin-right: 10px;
  color: #2F2F32;
  text-transform: uppercase;
}

.promotion_listing_sorting form select {
  display: inline-block;
  color: #2F2F32;
  text-transform: uppercase;
  box-shadow: none;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  vertical-align: middle;
}

.promotion_listing_sorting form i {
  display: inline-block;
  vertical-align: middle;
  color: #2F2F32;
}

.promotion_listing_sorting form .select2 .select2-selection {
  border: none;
  outline: none !important;
}

.promotion_listing_sorting form .select2 .select2-selection__rendered {
  color: #2F2F32;
  outline: none !important;
  text-transform: uppercase;
  padding-left: 0;
}

.promotion_listing_sorting form .select2 .select2-selection__arrow {
  height: auto !important;
  top: 4px !important;
}

.promotion_listing_sorting form .select2 .select2-selection__arrow:after {
  content: '\f107';
  font-family: 'fontAwesome';
  font-size: 14px;
  color: #2F2F32;
}

.promotion_listing_sorting form .select2 .select2-selection__arrow b {
  display: none;
}

.promotion_listing_sorting form #daterange {
  color: #2F2F32;
  outline: none !important;
  text-transform: uppercase;
  padding-left: 0;
  cursor: pointer;
}

.promotion_listing_sorting form #daterange span {
  padding-left: 5px;
}

.promotion_listing_sorting form #daterange span:after {
  content: '\f107';
  font-family: 'fontAwesome';
  font-size: 14px;
  color: #2F2F32;
}

.select2-container .select2-dropdown {
  border: 1px solid #B8B8B8 !important;
  background: #F4F4F4;
  border-radius: 10px;
  padding: 10px;
  width: 200px !important;
}

.select2-container .select2-results ul.select2-results__options li.select2-results__option {
  color: #2F2F32;
  text-transform: uppercase;
  background: transparent !important;
}

.select2-container .select2-results ul.select2-results__options li.select2-results__option.select2-results__option--highlighted {
  background: transparent;
  color: #EA0005;
}

.daterangepicker {
  border: 1px solid #B8B8B8 !important;
  background: #F4F4F4;
  border-radius: 10px;
  padding: 10px;
}

.daterangepicker.opensright:after, .daterangepicker.opensright:before {
  display: none;
}

.daterangepicker .calendar-date {
  background: #F4F4F4;
}

.daterangepicker .calendar-date table thead tr:first-child th {
  border-top: none !important;
  font-family: 'Gotham-Book';
}

.daterangepicker .calendar-date table thead tr th {
  font-family: 'Gotham-Book';
}

.daterangepicker .calendar-date table thead td.available:hover, .daterangepicker .calendar-date table thead td.available.today, .daterangepicker .calendar-date table thead th.available:hover {
  background: #E2E2E2;
}

.daterangepicker .calendar-date table tbody tr td, .daterangepicker .calendar-date table tbody tr th {
  font-family: 'Gotham-Book';
}

.daterangepicker .calendar-date table tbody tr td.available:hover, .daterangepicker .calendar-date table tbody tr td.available.today, .daterangepicker .calendar-date table tbody tr th.available:hover {
  background: #E2E2E2;
}

.daterangepicker .calendar-date table tbody tr td.today.active, .daterangepicker .calendar-date table tbody tr td.active, .daterangepicker .calendar-date table tbody tr td.active:hover {
  background-color: #EA0005;
  border-color: #EA0005;
}

.news_listing_list {
  margin-top: 40px;
}

@media (max-width: 480px) {
  .news_listing_list {
    margin-top: 15px !important;
  }
}

.news_listing_list .news_list {
  margin: 0 -25px;
  display: none;
  opacity: 0;
}

.news_listing_list .news_list .news_item {
  margin: 0 25px;
}

@media (max-width: 480px) {
  .news_listing_list .news_list .news_item {
    margin-bottom: 15px !important;
  }
}

.news_listing_list .news_list .news_item a {
  background: #F4F4F4;
  display: block;
  box-shadow: 1px 1px 5px 0 transparent;
  transition: all 0.3s linear;
}

.news_listing_list .news_list .news_item a:hover {
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.25);
}

.news_listing_list .news_list .news_item a > div .news_item_image img {
  width: 100%;
}

.news_listing_list .news_list .news_item a > div .news_item_text {
  padding: 25px;
}

.news_listing_list .news_list .news_item a > div .news_item_text .news_item_name {
  color: #2F2F32;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.news_listing_list .news_list .news_item a > div .news_item_text .news_item_description {
  color: #757575;
}

.news_listing_list .news_list .news_item a > div .news_item_content {
  padding: 0 25px 25px;
}

.news_listing_list .news_list .news_item a > div .news_item_content .news_item_date {
  color: #2F2F32;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.news_listing_list .news_list .news_item a > div .news_item_content .news_item_date i {
  color: #CE0000;
  width: 15px;
}

.news_details {
  padding: 40px 0;
}

.news_details .news_banner .news_banner_list {
  opacity: 0;
}

.news_details .news_title {
  font-family: 'Exo-Black';
  font-size: 40px;
  color: #EA0407;
  text-transform: uppercase;
  margin: 30px 0;
}

.news_details .news_descriptions {
  margin-bottom: 30px;
}

.news_details .news_descriptions .news_details_date {
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
}

.news_details .news_descriptions .news_details_date i {
  color: #CE0000;
}

.news_details .news_descriptions .news_details_date span {
  font-family: 'Gotham-Bold';
  font-size: 14px;
  color: #2F2F32;
  text-transform: uppercase;
}

.news_details .news_descriptions .news_details_date span.label {
  color: #CE0000;
}

.news_details .news_content {
  font-family: 'Gotham-Book';
  font-size: 14px;
  color: #2F2F32;
  margin-bottom: 25px;
  line-height: 20px;
}

.news_details .news_button a {
  font-family: 'Exo-Bold';
  font-size: 14px;
  text-transform: uppercase;
  color: #FFFFFF;
  background: #AD131E;
  padding: 15px 35px;
  letter-spacing: 2px;
  border-radius: 50px;
  transition: all 0.3s linear;
}

.news_details .news_button a:hover {
  color: #FFFFFF;
  background: #EA0407;
}

.news_details .news_button a i {
  padding-right: 10px;
  color: #FFFFFF;
}

.gallery_listing_list {
  margin-top: 40px;
}

@media (max-width: 480px) {
  .gallery_listing_list {
    margin-top: 15px;
  }
}

.gallery_listing_list .gallery_list {
  margin: 0 -25px;
  display: none;
  opacity: 0;
}

.gallery_listing_list .gallery_list .gallery_item {
  margin: 0 25px;
}

@media (max-width: 480px) {
  .gallery_listing_list .gallery_list .gallery_item {
    margin-bottom: 15px !important;
  }
}

.gallery_listing_list .gallery_list .gallery_item a {
  background: #F4F4F4;
  display: block;
  box-shadow: 1px 1px 5px 0 transparent;
  transition: all 0.3s linear;
}

.gallery_listing_list .gallery_list .gallery_item a:hover {
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.25);
}

.gallery_listing_list .gallery_list .gallery_item a > div .gallery_item_image img {
  width: 100%;
}

.gallery_listing_list .gallery_list .gallery_item a > div .gallery_item_text {
  padding: 25px;
}

.gallery_listing_list .gallery_list .gallery_item a > div .gallery_item_text .gallery_item_name {
  color: #2F2F32;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.gallery_listing_list .gallery_list .gallery_item a > div .gallery_item_text .gallery_item_description {
  color: #757575;
}

.gallery_listing_list .gallery_list .gallery_item a > div .gallery_item_content {
  padding: 0 25px 25px;
}

.gallery_listing_list .gallery_list .gallery_item a > div .gallery_item_content .gallery_item_date, .gallery_listing_list .gallery_list .gallery_item a > div .gallery_item_content .gallery_item_location {
  color: #2F2F32;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.gallery_listing_list .gallery_list .gallery_item a > div .gallery_item_content .gallery_item_date i, .gallery_listing_list .gallery_list .gallery_item a > div .gallery_item_content .gallery_item_location i {
  color: #CE0000;
  width: 15px;
}

.gallery_details {
  padding: 40px 0;
}

.gallery_details .gallery_title {
  font-family: 'Exo-Black';
  font-size: 40px;
  color: #EA0407;
  text-transform: uppercase;
  margin: 30px 0;
}

.gallery_details .gallery_descriptions {
  margin-bottom: 30px;
}

.gallery_details .gallery_descriptions .gallery_details_date, .gallery_details .gallery_descriptions .gallery_details_location {
  display: block;
  vertical-align: middle;
  margin-right: 15px;
  margin-bottom: 5px;
}

.gallery_details .gallery_descriptions .gallery_details_date i, .gallery_details .gallery_descriptions .gallery_details_location i {
  color: #CE0000;
  width: 10px;
}

.gallery_details .gallery_descriptions .gallery_details_date span, .gallery_details .gallery_descriptions .gallery_details_location span {
  font-family: 'Gotham-Bold';
  font-size: 14px;
  color: #2F2F32;
  text-transform: uppercase;
}

.gallery_details .gallery_descriptions .gallery_details_date span.label, .gallery_details .gallery_descriptions .gallery_details_location span.label {
  color: #CE0000;
}

.gallery_details .gallery_content {
  font-family: 'Gotham-Book';
  font-size: 14px;
  color: #2F2F32;
  margin-bottom: 25px;
  line-height: 20px;
}

.gallery_details .gallery_button a {
  font-family: 'Exo-Bold';
  font-size: 14px;
  text-transform: uppercase;
  color: #FFFFFF;
  background: #AD131E;
  padding: 15px 35px;
  letter-spacing: 2px;
  border-radius: 50px;
  transition: all 0.3s linear;
}

.gallery_details .gallery_button a:hover {
  color: #FFFFFF;
  background: #EA0407;
}

.gallery_details .gallery_button a i {
  padding-right: 10px;
  color: #FFFFFF;
}

#frmContact .btn {
  font-family: 'Exo-Bold';
  font-size: 14px;
  text-transform: uppercase;
  color: #FFFFFF;
  background: #AD131E;
  height: auto;
  border: none;
  padding: 10px 35px;
  letter-spacing: 2px;
  border-radius: 50px;
  transition: all 0.3s linear;
}

#frmContact .btn:hover {
  color: #FFFFFF;
  background: #EA0407;
}

#frmContact .btn i {
  padding-right: 10px;
  color: #FFFFFF;
}

@media (max-width: 991px) {
  #frmContact .g-recaptcha > div {
    margin: 0 auto;
  }
}

@media (max-width: 991px) {
  #frmContact .form-group {
    text-align: center;
  }
}

.mfp-content .btnCloseMagnific {
  font-family: 'Exo-Bold';
  font-size: 14px;
  text-transform: uppercase;
  color: #FFFFFF;
  background: #AD131E;
  height: auto;
  border: none;
  padding: 10px 35px;
  letter-spacing: 2px;
  border-radius: 50px;
  margin-top: 20px;
  transition: all 0.3s linear;
}

.mfp-content .btnCloseMagnific:hover {
  color: #FFFFFF;
  background: #EA0407;
}

.mfp-content .btnCloseMagnific i {
  padding-right: 10px;
  color: #FFFFFF;
}

.mfp-content .mfp-close {
  color: black !important;
}

.search-listing-wrapper {
  padding-top: 40px;
  padding-bottom: 40px;
}

.search-listing-wrapper .search-listing {
  margin-bottom: 30px;
}

.search-listing-wrapper .search-listing > a {
  width: 100%;
}

.search-list {
  padding: 20px;
  box-shadow: 0 -1px 7px 0 rgba(0, 0, 0, 0.25);
}

.search-list .search-item-top {
  margin-bottom: 25px;
}

.search-list .search-item-top .search-item-top-logo {
  font-size: 0;
}

.search-list .search-item-top .search-item-top-logo:before {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  /* vertical alignment of the inline element */
  height: 100%;
}

.search-list .search-item-top .search-item-top-logo img {
  display: inline-block;
  vertical-align: middle;
  /* vertical alignment of the inline element */
}

.search-list .search-item-top .search-item-top-title {
  font-size: 0;
  padding-left: 0;
}

.search-list .search-item-top .search-item-top-title:before {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  /* vertical alignment of the inline element */
  height: 100%;
}

.search-list .search-item-top .search-item-top-title .wrap {
  display: inline-block;
  vertical-align: middle;
}

.search-list .search-item-top .search-item-top-title .wrap .title {
  color: #EA0005;
  text-transform: uppercase;
  margin-bottom: 8px;
  letter-spacing: 1px;
}

.search-list .search-item-top .search-item-top-title .wrap .category {
  color: #FFFFFF;
  background: #EA0005;
  padding: 5px 12px;
  border-radius: 25px;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 10px;
}

.search-list .search-item-image {
  margin-bottom: 20px;
  width: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.search-list .search-item-image .image {
  width: 100%;
  position: relative;
  text-align: center;
}

.search-list .search-item-extras .search-item-extras-content {
  margin-bottom: 10px;
}

.search-list .search-item-extras .search-item-extras-content i {
  color: #CE0000;
  width: 25px;
  font-size: 16px;
}

.search-list .search-item-extras .search-item-extras-content span {
  color: #2F2F32;
}

.vertical-align-div {
  display: inline-block;
  vertical-align: middle;
  content: '';
  width: 1px;
  height: 100%;
}

#header-content .inner #audio .audio_status span, #header-content .inner #audio_status_mobile span {
  color: #2F2F32;
}

#audio_status_mobile {
  position: absolute;
  top: 20%;
  right: 30px;
}

#audio_status_mobile img {
  display: block;
  margin-top: 10px;
}

#audio_status_mobile span {
  color: #FFFFFF;
  text-transform: uppercase;
  display: block;
  text-align: center;
  margin-top: 5px;
}

#audio {
  text-align: right;
}

#audio .audio_status {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}

#audio .audio_status span {
  color: #FFFFFF;
  text-transform: uppercase;
  margin-left: 15px;
}

#audio #audio_source {
  display: none;
}

#audio audio:not([controls]), #audio .mejs-container {
  display: none !important;
  height: 0 !important;
}

.sitemaps {
  margin-top: 15px;
}

.sitemaps > ul > li > a {
  font-size: 20px;
  font-family: 'Gotham-Book';
  color: #2F2F32 !important;
  margin-bottom: 10px;
  background: transparent !important;
  box-shadow: none !important;
  transition: all 0.3s linear !important;
}

.sitemaps > ul > li > a:hover {
  color: #ea0005 !important;
}

.sitemaps > ul > li > ul > li a {
  font-size: 12px;
  font-family: 'Gotham-Book';
  color: #2F2F32 !important;
  margin-bottom: 10px;
  background: transparent !important;
  box-shadow: none !important;
  transition: all 0.3s linear !important;
}

.sitemaps > ul > li > ul > li a:hover {
  color: #ea0005 !important;
}

#contactContainer .contactus-information > div {
  padding: 0 !important;
}

#contactContainer .contactus-information > div h3 {
  font-family: 'Exo-Black';
}

#contactContainer .contactus-information > div .contact-information-text {
  padding-left: 30px;
}

@media (max-width: 991px) {
  #contactContainer .contactus-information > div .contact-information-text {
    padding-left: 0;
  }
}

.contact-information-section {
  margin-bottom: 15px;
}

@media (max-width: 991px) {
  .contact-information-section {
    width: 49%;
    display: inline-block;
    vertical-align: top;
  }
}

@media (max-width: 480px) {
  .contact-information-section {
    width: 100%;
  }
}

.contact-information-section .contact-information-section-title {
  font-family: 'Exo-Bold';
  font-size: 18px;
  margin-bottom: 5px;
  color: #4B1116;
}

.contact-information-section .contact-information-section-description {
  font-family: 'Gotham-Book';
  font-size: 14px;
  line-height: 20px;
}

.contact-information-section .contact-information-section-description span {
  color: #AD131E;
}

.contact-information-section .contact-information-section-extras {
  font-family: 'Gotham-Book';
  font-size: 14px;
  line-height: 20px;
}

.contact-information-section .contact-information-section-extras span {
  color: #AD131E;
}

#privacy_data_link {
  color: #95101A;
  cursor: pointer;
  transition: all 0.3s linear;
}

#privacy_data_link:hover {
  color: #EA0407;
}

#privacy-popup {
  background-color: white;
  padding: 15px;
  max-height: 550px;
  width: 800px;
  max-width: 100%;
  overflow: auto;
}

.privacy-close {
  margin-top: 30px;
  margin-bottom: 10px;
  text-align: right;
}

.privacy-close-button {
  padding: 10px 25px;
  background: #95101A;
  color: #FFFFFF;
  font-family: 'Exo-Bold';
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s linear;
}

.privacy-close-button:hover {
  background: #EA0407;
}

@media screen and (max-width: 325px) {
  #rc-imageselect, .g-recaptcha {
    transform: scale(0.95);
    -webkit-transform: scale(0.95);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}

#mobile-menu .mm-close {
  color: #000;
}

#mobile-menu .mm-close:after {
  content: '\f00d';
  font-family: 'fontAwesome';
  font-size: 15px;
}

#mobile-menu .mm-title {
  font-size: 18px;
  color: #000;
}

#mobile-menu .mm-prev {
  color: #000;
}

#mobile-menu .mm-prev:before {
  border-color: #000;
}

#mobile-menu .mm-listview li a {
  font-size: 18px;
  color: #000;
}

#mobile-menu .mm-listview li a.mm-next:after {
  border-color: #000;
}

/*# sourceMappingURL=app.css.map */
